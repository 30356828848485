define("ember-svg-jar/inlined/icn-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#6ED191\" fill-rule=\"evenodd\" d=\"M12.413 2.57L10.807.963a1.216 1.216 0 00-1.72 0L2.093 7.96a1.302 1.302 0 00-.308.52l-.99 3.394c-.065.194 0 .406.145.568.114.114.26.162.406.162.049 0 .114 0 .162-.016l3.391-.99a1.3 1.3 0 00.52-.31l6.994-6.999a1.218 1.218 0 000-1.72zm-9.72 6.853L3.96 10.69l-1.785.52.52-1.787zm8.909-5.927L10.498 4.6l-1.72-1.722 1.104-1.104c.016-.017.048-.017.064-.017a.12.12 0 01.065.017l1.606 1.607c.017.017.017.05.017.065 0 .017-.016.033-.032.05z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 13 13"
    }
  };
});