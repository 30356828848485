define("ember-svg-jar/inlined/icn-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.348 9.326a.569.569 0 01-.571.552h-.833a.567.567 0 01-.57-.552v-.807c0-.305.255-.552.57-.552h.833c.315 0 .571.247.571.552v.807zm3.204-.807a.566.566 0 00-.571-.552h-.833a.567.567 0 00-.57.552v.807c0 .305.255.552.57.552h.833a.567.567 0 00.56-.552l.011-.807zm1.79 1.367h.833c.315 0 .57-.247.57-.552v-.806a.568.568 0 00-.57-.553h-.842a.568.568 0 00-.562.553v.806c.009.305.265.55.57.546v.006h.001zm-5.567 1.281h-.833a.583.583 0 00-.41.158.543.543 0 00-.17.394v.807c0 .304.256.552.571.552h.833c.152.003.3-.054.411-.158a.55.55 0 00.171-.394v-.807a.544.544 0 00-.168-.391.577.577 0 00-.405-.161zm3.204 0h-.833a.583.583 0 00-.41.158.543.543 0 00-.17.394v.807c0 .304.256.552.572.552h.833c.315 0 .57-.248.57-.552v-.807a.566.566 0 00-.562-.552zM1.8 16.443h7.806a5.693 5.693 0 01-.335-1.925H1.979V6.503h14.13v2.16a6.213 6.213 0 011.99.582V3.743C18.096 2.78 17.291 2 16.298 2h-1.912V.69a.708.708 0 00-.701-.69H12.56c-.19 0-.37.073-.504.202a.672.672 0 00-.21.488V2H6.25V.69a.707.707 0 00-.708-.688H4.423a.708.708 0 00-.71.688V2H1.8C.806 2.002 0 2.782 0 3.744V14.7c0 .962.806 1.742 1.8 1.742v.001zM20 14.518a4.414 4.414 0 01-1.357 3.169A4.709 4.709 0 0115.37 19a4.714 4.714 0 01-3.275-1.313 4.41 4.41 0 01-1.357-3.17c0-1.188.488-2.329 1.357-3.169a4.714 4.714 0 013.275-1.313 4.711 4.711 0 013.274 1.313A4.413 4.413 0 0120 14.518zm-2.608 1.234a.702.702 0 00-.209-.508l-1.063-1.029V12.62a.737.737 0 00-.73-.72h-.012a.74.74 0 00-.743.72v2.197l1.498 1.45c.214.2.531.258.805.146a.723.723 0 00.454-.66v-.001z\" fill=\"#bbc9cf\"/>",
    "attrs": {
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});