define("ember-svg-jar/inlined/icn-layers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#65615a\"><path d=\"M23.657 12.152l-3.211-1.713-1.683.898 2.625 1.4-8.604 4.588a1.016 1.016 0 01-.955 0l-8.608-4.588 2.625-1.399-1.683-.897-3.212 1.713a.663.663 0 00-.343.583c0 .246.134.472.343.582l10.177 5.428a2.501 2.501 0 002.353 0l10.175-5.427a.655.655 0 00.343-.582.66.66 0 00-.341-.586h-.001z\"/><path d=\"M.951 6.933l10.177 5.426a2.495 2.495 0 002.353 0l10.176-5.426A.658.658 0 0024 6.35a.66.66 0 00-.343-.584L13.482.337a2.492 2.492 0 00-2.354-.001L.951 5.765a.635.635 0 00-.303-.077.657.657 0 00-.648.661c0 .362.292.661.648.661a.646.646 0 00.303-.077z\"/></g>",
    "attrs": {
      "viewBox": "0 0 24 19",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});