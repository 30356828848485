define("ember-svg-jar/inlined/icn-na", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(.447)\"><path fill=\"#BBB8B3\" d=\"M3.576 4.856a.935.935 0 001.318.005.91.91 0 00-.004-1.304l-1.3-1.28a.935.935 0 00-1.318-.01.906.906 0 00.01 1.304l1.294 1.285zm13.79 3.478h-1.565c-.445 0-.801.418-.801.935 0 .523.348.94.8.94h1.566c.444 0 .8-.417.8-.94 0-.517-.352-.935-.8-.935M3.167 9.27a.786.786 0 00-.8-.78H.8a.788.788 0 00-.801.78c0 .436.351.784.8.784h1.567c.444 0 .8-.348.8-.784M5 9.272c0 1.001.35 1.923.934 2.652.792.986 5.986.984 6.778-.006.583-.728.93-1.648.93-2.646C13.642 6.912 11.707 5 9.32 5 6.934 5 5 6.912 5 9.272zm9.592-4.416l1.296-1.285a.913.913 0 00.01-1.304.936.936 0 00-1.32.01l-1.3 1.28a.91.91 0 00-.005 1.304.937.937 0 001.32-.005M9.32 3.13c.44 0 .793-.347.793-.79V.792A.792.792 0 009.319 0a.786.786 0 00-.79.792V2.34c0 .44.352.79.79.79\"/><rect width=\"18\" height=\"1.4\" y=\"11.91\" fill=\"#676767\" rx=\".7\"/><rect width=\"10\" height=\"1.4\" x=\"2\" y=\"14.78\" fill=\"#676767\" rx=\".7\"/><rect width=\"6\" height=\"1.4\" x=\"6\" y=\"17.56\" fill=\"#676767\" rx=\".7\"/><rect width=\"2\" height=\"1.4\" x=\"7\" y=\"20.34\" fill=\"#676767\" rx=\".7\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 22"
    }
  };
});