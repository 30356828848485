define("ember-svg-jar/inlined/icn-flow-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#C6D3DA\" fill-rule=\"evenodd\" d=\"M64 41.272v10.274H49.539V41.272H64zm-44.542-25.83c0 7.396 5.485 12.069 12.542 12.069 7.198 0 12.542-4.787 12.542-12.069 0-6.773-5.614-12.26-12.542-12.26S19.458 8.67 19.458 15.443zm12.246-2.1l-6.336-7.308 4.816 8.339c-.927 1.77 1.21 3.615 2.898 2.47 1.7-1.16.644-3.743-1.378-3.501zM41.374 58H22.626c-3.155 0-5.73-2.52-5.73-5.601V40.406c0-3.08 2.575-5.6 5.73-5.6h2.833V29.47c-5.498-2.215-9.271-7.282-9.271-14.029C16.187 6.912 23.269 0 32 0c8.73 0 15.813 6.913 15.813 15.442 0 6.136-3.103 10.936-7.88 13.418v5.958h1.455c3.154 0 5.73 2.52 5.73 5.601v11.992C47.105 55.48 44.53 58 41.375 58zM14.462 41.272v10.274H0V41.272h14.461z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 58"
    }
  };
});