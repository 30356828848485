define("ember-svg-jar/inlined/icn-sensor-yearly-eto", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icnEToYearly</title><path d=\"M25.526 11.008v.72c0 4.445-3.654 8.06-8.145 8.06h-1.895v7.538h-1.458V14.62h-1.409c-4.491 0-8.145-3.615-8.145-8.06V5.84h3.928c3.895 0 7.064 3.124 7.084 6.972v1.666a7.1 7.1 0 016.112-3.47h3.928zM18.785 2.663a.504.504 0 00-.715.003l-2.337 2.358c-.542.55.27 1.376.812.826l1.013-1.03c.169-.17.305-.113.305.127v2.826a.58.58 0 00.575.584.58.58 0 00.575-.584V4.947c0-.24.136-.298.306-.126L20.33 5.85c.543.55 1.355-.276.813-.826l-2.358-2.36z\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});