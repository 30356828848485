define("ember-svg-jar/inlined/icn-schedule-single-fertigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.998 54C16.03 54 8 46.085 8 36.263c0-3.307.914-6.412 2.542-9.019.306-.603.711-1.104 1.12-1.605l.104-.102c.202-.301.405-.5.611-.7L25.998 8l13.728 16.735c.103.101.203.3.306.399l.306.4C42.574 28.538 44 32.246 44 36.253 43.996 45.983 35.964 54 25.998 54z\" fill=\"#6ed191\"/>",
    "attrs": {
      "viewBox": "0 0 52 58",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});