define("ember-svg-jar/inlined/icn-sensor-electrical-conductivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.62 14.45l1.1-1.33 2.45-2.85h2.54l-3.44 4L19.92 19H17.2l-2.45-3.33-1.12.71V19h-2.38v-8.73h2.38v2.28l-.02 1.26-.02.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});