define("ember-svg-jar/inlined/icn-menu-calibration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title/><path d=\"M18 4h1V2H9v2h1v2H6V2H4v4H2v2h2v9.18A3 3 0 002 20v2h6v-2a3 3 0 00-2-2.82V8h4v8a4 4 0 008 0V8h4V6h-4zm-2 6h-4V4h4z\" data-name=\"Layer 2\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});