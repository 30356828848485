define("ember-svg-jar/inlined/icn-sensor-dew-point", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23 7.91c0-.7-.24-1.34-.61-1.86l-.05-.07c-.02-.02-.03-.05-.05-.07L20 3l-2.27 2.93c-.03.03-.07.07-.1.12l-.02.02c-.07.09-.14.17-.19.28-.27.45-.42.99-.42 1.57 0 1.7 1.34 3.08 3 3.08s3-1.39 3-3.09zm4.19 4.9l-.07-.09c-.02-.02-.04-.06-.07-.09L24 9l-3.03 3.66c-.05.04-.09.09-.14.15l-.02.02c-.09.11-.18.22-.25.35-.36.57-.56 1.25-.56 1.96 0 2.14 1.78 3.86 4 3.86 2.21 0 4-1.74 4-3.86 0-.87-.32-1.67-.81-2.33zM14.24 6.28c0-2.37-1.82-4.28-4.1-4.28-2.28 0-4.1 1.91-4.1 4.28v10.27C4.77 17.75 4 19.34 4 21.09c0 3.46 2.85 6.36 6.21 6.36 3.34 0 6.07-2.88 6.07-6.36 0-1.74-.77-3.34-2.05-4.54V6.28h.01zm-4.03 19.05c-2.24 0-4.17-1.96-4.17-4.24 0-1.22.61-2.36 1.66-3.21.25-.2.39-.51.39-.84V6.28c0-1.2.91-2.16 2.05-2.16 1.14 0 2.05.96 2.05 2.16v10.77c0 .33.14.63.39.84 1.05.85 1.66 1.99 1.66 3.21 0 2.31-1.82 4.23-4.03 4.23z\" fill-rule=\"nonzero\"/><path d=\"M11.23 18.24v-2.22c0-.5-.28-1.02-1.06-1.02s-1.06.42-1.06 1.02v2.22C7.89 18.66 7 19.78 7 21.11c0 1.69 1.42 3.05 3.18 3.05 1.76 0 3.18-1.37 3.18-3.05-.01-1.33-.9-2.45-2.13-2.87z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});