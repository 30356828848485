define("ember-svg-jar/inlined/icn-event-inferred-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.037 53.985h-.034c-9.691 0-17.668-7.977-17.668-17.669v-.033c0-3.301.9-6.399 2.5-9.002.301-.6.698-1.1 1.103-1.6l.1-.104c.199-.298.4-.5.6-.698L26.038 8.076 39.538 24.78c.1.1.198.298.302.396l.299.4c2.2 3 3.603 6.7 3.603 10.7-.003 9.708-7.905 17.707-17.708 17.707l.003.003z\" fill=\"#b9e6f7\"/><path d=\"M25.906 52.586h.13c8.994 0 16.306-7.34 16.31-16.311 0-3.525-1.193-6.955-3.322-9.86l-.153-.232a2.214 2.214 0 01-.344-.43l-.003-.005a.813.813 0 00-.072-.092l-12.41-15.35-12.423 15.57c-.159.143-.299.302-.419.475l-.173.215v-.011c-.53.653-.748.964-1.003 1.458a15.729 15.729 0 00-2.293 8.27c-.016 8.901 7.273 16.25 16.175 16.303z\" fill=\"none\" stroke=\"#00a2e1\" stroke-width=\"2.79299\"/>",
    "attrs": {
      "viewBox": "0 0 52 58",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd"
    }
  };
});