define("ember-svg-jar/inlined/icn-sensor-chilling-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M3.13 21.86c.11 0 .21-.03.31-.08l2.1-1.17v1.76c0 .33.28.6.63.6s.63-.27.63-.6v-2.46l3.61-2v4.04l-2.16 1.2c-.3.17-.4.54-.23.82.12.19.33.3.54.3.11 0 .21-.03.31-.08l1.53-.85v2.3c0 .33.28.6.63.6s.63-.27.63-.6v-2.3l1.53.85c.1.05.21.08.31.08.22 0 .43-.11.54-.3.17-.29.07-.66-.23-.82l-2.16-1.2v-4.04l3.63 2.02v2.45c0 .33.28.6.63.6s.63-.27.63-.6v-1.75l2.07 1.15c.1.05.21.08.31.08.22 0 .43-.11.54-.3.17-.29.07-.66-.23-.82l-2.03-1.12 1.48-.82c.3-.17.4-.54.23-.82a.64.64 0 00-.86-.22l-2.11 1.17-3.68-2.04 3.63-1.85-.58-1.04-3.65 1.83v-3.68l2.16-1.2c.3-.17.4-.54.23-.82a.64.64 0 00-.86-.22l-1.53.85V8.1c0-.33-.28-.6-.63-.6s-.63.27-.63.6v2.66l-1.53-.85a.642.642 0 00-.86.22.59.59 0 00.24.82l2.16 1.2v3.68l-3.61-2v-2.1c0-.33-.28-.6-.63-.6s-.63.27-.63.6v1.4l-2.1-1.17a.642.642 0 00-.86.22c-.17.29-.07.66.23.82l2.36 1.31-1.79 1c-.3.17-.4.54-.23.82.12.19.33.3.54.3.11 0 .21-.03.31-.08l2.42-1.34 3.34 1.85-3.67 2.03-2.1-1.16a.642.642 0 00-.86.22c-.17.29-.07.66.23.82l1.47.82-2.04 1.13c-.3.17-.4.54-.23.82.14.21.35.32.57.32zm18.37-9.47V8.13c0-.35.26-.63.59-.63.32 0 .59.28.59.63v3h2.08c.32 0 .59.28.59.63s-.26.63-.59.63\"/><path d=\"M28.5 11.74c0 3.65-2.92 6.61-6.5 6.61s-6.5-2.97-6.5-6.61c0-3.02 2-5.57 4.72-6.36V4.5h3.49v.86c2.76.77 4.79 3.34 4.79 6.38zm-1.25 0C27.25 8.8 24.9 6.4 22 6.4s-5.25 2.4-5.25 5.34 2.35 5.34 5.25 5.34 5.25-2.4 5.25-5.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});