define("ember-svg-jar/inlined/icn-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7 8c-.324 0-.634-.114-.885-.357L.365 2.069a1.19 1.19 0 010-1.715 1.29 1.29 0 011.77 0L7 5.07 11.866.354a1.288 1.288 0 011.769 0 1.19 1.19 0 010 1.715l-5.75 5.574C7.635 7.886 7.325 8 7 8z\" fill=\"#98948e\"/>",
    "attrs": {
      "viewBox": "0 0 14 8",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});