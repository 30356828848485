define("ember-svg-jar/inlined/icn-schedule-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M57.3 5.4h-6.8V3c0-1.6-1.4-3-3.1-3h-2.2c-1.7 0-3.1 1.3-3.1 3v2.4H19.4V3c0-1.6-1.4-3-3.1-3h-2.2C12.4 0 11 1.3 11 3v2.4H4.4C2 5.4 0 7.3 0 9.6v47.9c0 2.3 2 4.2 4.4 4.2h52.9c2.4 0 4.4-1.9 4.4-4.2V9.6c0-2.3-2-4.2-4.4-4.2zm.8 15.4v36.6c0 .4-.4.7-.8.7H4.4c-.4 0-.8-.3-.8-.7V20.8h54.5zm-43.4-9.7V3.5h1.2v7.7l-1.2-.1zm-.5 3.5h2.2c1.7 0 3.1-1.3 3.1-3V8.8h22.8v2.8c0 1.6 1.4 3 3.1 3h2.2c1.7 0 3.1-1.3 3.1-3V8.8h6.8c.4 0 .8.3.8.7v7.8H3.6V9.6c0-.4.4-.7.8-.7h6.8v2.8c-.1 1.6 1.3 2.9 3 2.9zm31.6-3.5V3.5H47v7.7l-1.2-.1z\"/><path d=\"M42.8 32.4c0-.4-.3-.8-.6-1.1-.7-.6-1.7-.3-2.3.3L27.3 46.3l-5-4.6c-.7-.6-1.7-.6-2.4 0-.3.3-.5.7-.5 1.1s.2.8.5 1.1l6.6 6.1c.2.1.5.2.8.2h.1c.5 0 .9-.2 1.2-.6l13.8-15.9c.3-.3.5-.9.4-1.3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 61.7 61.7"
    }
  };
});