define("ember-svg-jar/inlined/icn-autom-unknown-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(1.168 7.581)\" fill=\"none\" fill-rule=\"evenodd\"><rect fill=\"#BBB8B3\" width=\"16.61\" height=\"15.946\" rx=\"7.973\"/><path d=\"M7.986 9.116l-.19-5.436H9.43l-.18 5.436H7.986zm1.319 1.465a.968.968 0 01.278.7.97.97 0 01-.278.693.903.903 0 01-.68.292.903.903 0 01-.68-.292.97.97 0 01-.279-.693c0-.276.093-.509.278-.7a.913.913 0 01.68-.285c.269 0 .495.095.68.285z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "viewBox": "0 0 18 31",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});