define("ember-collection/utils/needs-revalidate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = needsRevalidate;
  function needsRevalidate(view) {
    view._renderNode.isDirty = true;
    view._renderNode.ownerNode.emberView.scheduleRevalidate(view._renderNode, view.toString(), 'rerendering via needsRevalidate');
  }
});