define("ember-svg-jar/inlined/icn-sensor-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M14.35 5.15a.45.45 0 00-.37.28l-3.52 9.46c-.1.27.13.6.41.6h2.23l-3.09 8.43c-.07.19.02.42.19.52.17.1.41.07.55-.07l11-11.88c.11-.12.14-.32.08-.47a.455.455 0 00-.4-.26h-3.77l2.2-6.01c.1-.26-.13-.59-.41-.59l-5.1-.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});