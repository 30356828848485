define("ember-svg-jar/inlined/icn-copy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(.073 .5)\" fill=\"none\" fill-rule=\"evenodd\"><rect stroke=\"#BBB8B3\" fill=\"#FFF\" x=\".5\" y=\".5\" width=\"19\" height=\"19\" rx=\"4\"/><path d=\"M12.157 3.474c.654 0 1.185.53 1.185 1.184v.816h.815c.654 0 1.185.53 1.185 1.184v8.684c0 .653-.53 1.184-1.185 1.184H7.843c-.654 0-1.185-.53-1.185-1.184v-.817h-.815c-.654 0-1.185-.53-1.185-1.183V6.435c0-.094.034-.184.094-.255l2.17-2.566a.395.395 0 01.302-.14h4.933zm1.807 3.08h-.623v6.788c0 .653-.53 1.184-1.184 1.184H7.665v.55c0 .204.167.37.371.37h5.928a.37.37 0 00.37-.37V6.924a.37.37 0 00-.37-.37zm-7.187.594H5.666v5.927c0 .205.166.37.37.37h5.928a.37.37 0 00.37-.37v-8.15a.37.37 0 00-.37-.37H7.889v1.481c0 .614-.498 1.112-1.112 1.112z\" fill=\"#BBB8B3\" fill-rule=\"nonzero\"/><path d=\"M11.497 7.69h-.772c-.063 0-.114.073-.114.164v4.902c0 .044.012.086.034.117.021.03.05.048.08.048h.772a.1.1 0 00.081-.048.208.208 0 00.033-.117V7.854a.208.208 0 00-.033-.116c-.021-.03-.05-.048-.08-.048zM9.409 9.431h-.772a.112.112 0 00-.114.11v3.27c0 .03.012.057.033.078.022.02.05.032.08.032h.773c.03 0 .059-.012.08-.032a.108.108 0 00.034-.078v-3.27a.108.108 0 00-.034-.078.116.116 0 00-.08-.032zm-2.089.873h-.772c-.063 0-.114.037-.114.082v2.453c0 .022.012.042.034.058a.14.14 0 00.08.024h.772a.14.14 0 00.08-.024c.022-.016.034-.036.034-.058v-2.453a.073.073 0 00-.033-.058.14.14 0 00-.08-.024z\" fill=\"#BBB8B3\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});