define("ember-svg-jar/inlined/icn-angle-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M7.002 7.998c-.324 0-.634-.114-.885-.357L.367 2.067a1.19 1.19 0 010-1.715 1.295 1.295 0 011.77 0l4.865 4.716L11.868.352a1.294 1.294 0 011.77 0 1.19 1.19 0 010 1.715L7.887 7.64c-.25.243-.56.357-.886.357z\" fill=\"#bbb8b3\"/>",
    "attrs": {
      "viewBox": "0 0 14 8",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});