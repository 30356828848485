define("ember-svg-jar/inlined/icn-schedule-print-fertigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"a\" d=\"M8.499 22A8.475 8.475 0 010 13.517c0-1.582.431-3.066 1.2-4.313.145-.289.336-.528.53-.768l.048-.049c.096-.144.192-.24.29-.335L8.498 0l6.483 8.003c.048.05.095.145.144.191l.145.191C16.327 9.823 17 11.596 17 13.513 16.998 18.166 13.205 22 8.5 22h-.001z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path stroke=\"#676767\" d=\"M8.436 21.5h.063c4.414 0 8-3.594 8.001-7.987 0-1.726-.584-3.405-1.628-4.826l-.092-.132a.902.902 0 01-.142-.178l-.001-.002a.475.475 0 00-.044-.057L8.502.798 2.417 8.41a1.43 1.43 0 00-.222.254l-.064.077-.012.009c-.26.322-.371.478-.493.716A7.698 7.698 0 00.5 13.517c0 4.4 3.535 7.95 7.936 7.983z\"/><path fill=\"#676767\" d=\"M10.963 16.187c-.895-1.09-2.154-2.294-3.049-3.125a.263.263 0 01-.084-.2c0-.172.112-.287.28-.287a.5.5 0 01.195.058h.028c1.259 1.175 2.266 2.179 2.993 3.039l.168-.344s0-.029.028-.058c.028-.086.056-.2.084-.286 0-.029.028-.058.028-.086.027-.086.027-.172.056-.258 0-.029 0-.058.028-.115-.028-.116-.028-.26-.028-.374 0-2.236-2.294-3.412-4.476-3.44-1.986-.029-3.077-.574-3.077-.574s.28 2.925.867 4.559c.672 1.864 1.902 2.61 3.61 2.61.11 0 .223 0 .335-.029h.028a.96.96 0 00.28-.058h.027c.196-.057.392-.114.587-.2.168-.086.336-.172.504-.287.56.66.923 1.176.923 1.405h.84c-.029-.43-.504-1.176-1.175-1.95z\" mask=\"url(#b)\" transform=\"scale(-1 1) rotate(-2 0 480.315)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 17 22"
    }
  };
});