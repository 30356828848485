define("ember-svg-jar/inlined/icn-menu-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M17.391 9.892h-1.467c-.12 0-.216.146-.216.328v9.758c0 .087.023.17.063.232.04.061.096.096.153.096h1.467c.058 0 .113-.035.153-.096a.428.428 0 00.064-.232V10.22a.428.428 0 00-.064-.232c-.04-.062-.095-.096-.153-.096zm-4.158 3.466h-1.466a.218.218 0 00-.217.22v6.509a.22.22 0 00.063.155c.041.04.096.064.154.064h1.466a.215.215 0 00.154-.064.22.22 0 00.063-.155v-6.51a.22.22 0 00-.063-.154.215.215 0 00-.154-.065zm-4.157 1.737H7.609c-.12 0-.217.074-.217.164v4.883c0 .043.023.085.064.116.04.03.095.048.153.048h1.467a.256.256 0 00.153-.048.147.147 0 00.063-.116v-4.883a.147.147 0 00-.063-.116.256.256 0 00-.153-.048z\"/><path d=\"M8.364 1.778a.786.786 0 01.6-.278h9.822a2.357 2.357 0 012.357 2.357v17.286a2.357 2.357 0 01-2.357 2.357H6.214a2.357 2.357 0 01-2.357-2.357V7.393c0-.186.066-.366.186-.508l4.321-5.107zm-.289 7.035H5.863v11.8c0 .407.33.737.737.737h11.8c.407 0 .738-.33.738-.738V4.389a.738.738 0 00-.738-.738h-8.112V6.6a2.213 2.213 0 01-2.213 2.213z\"/></g>",
    "attrs": {
      "viewBox": "0 0 25 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});