define("ember-svg-jar/inlined/icn-sensor-atmospheric-pressure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.81 10.39C20.76 8.25 18.03 7.03 15.12 7h-.1c-2.93 0-5.72 1.22-7.81 3.44A11.95 11.95 0 004 18.59v.05c0 1.72 1.35 3.12 3 3.12h5.26a3.7 3.7 0 002.76 1.25c.55 0 1.08-.12 1.56-.35.34-.15.72-.42 1.27-.9H23c1.63 0 2.95-1.35 3-3.04v-.27c0-3.05-1.13-5.89-3.19-8.06zm2.11 8.27c-.02 1.07-.87 1.92-1.9 1.92H17.5c-.77.67-1.11.9-1.32 1-.36.17-.72.25-1.11.25-.91 0-1.75-.47-2.26-1.25H7c-1.06 0-1.9-.87-1.9-1.97v-.05c0-2.74 1.03-5.36 2.91-7.35s4.37-3.09 7.01-3.09h.07c2.62.03 5.09 1.1 6.94 3.04 1.85 1.94 2.88 4.51 2.88 7.25v.25z\"/><path d=\"M14.71 17.45c-.85.35-1.22 1.27-.85 2.05.27.58.9.92 1.52.92.22 0 .47-.05.7-.14.85-.37 4.29-3.47 4.29-3.47s-4.81.3-5.66.64zm.55-5.8c.29 0 .51-.23.51-.53v-1c0-.3-.22-.53-.51-.53s-.51.23-.51.53v1c.02.29.24.53.51.53zm-6.67 6.19H7.6c-.3 0-.53.22-.53.52s.23.52.53.52h.99c.3 0 .53-.22.53-.52 0-.3-.23-.52-.53-.52zm2.96-6.99c-.15-.24-.45-.32-.69-.15-.21.17-.28.51-.13.78l.56.95a.5.5 0 00.41.24c.09 0 .19-.02.28-.1.21-.17.28-.51.13-.78l-.56-.94zm7.27 1.83c.15 0 .3-.07.41-.24l.56-.95c.15-.24.09-.61-.13-.78-.21-.17-.53-.1-.68.15l-.56.95c-.15.24-.11.61.13.78.08.06.16.09.27.09zm-9.48 1.59l-.92-.49c-.27-.14-.62-.05-.77.19-.15.26-.05.59.2.73l.92.49c.1.05.17.07.27.07.2 0 .4-.09.5-.28.18-.22.08-.54-.2-.71z\"/><path d=\"M21.73 12.82a.551.551 0 00-.74 0L17.3 15.9c.12 0 .25-.02.37-.02 1.29-.12 2.47-.19 2.52-.19h.07c.45 0 .84.24 1.04.64.2.4.1.9-.25 1.21-.03.02-.64.62-1.43 1.33h3.76c.32 0 .57-.24.57-.55v-.05c-.05-3.55-2.12-5.38-2.22-5.45z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});