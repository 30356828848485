define("ember-svg-jar/inlined/icn-system-health-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#C6D3DA\" fill-rule=\"evenodd\" d=\"M50 18.225H30.51l-3.524 10.03L21.298 4h-2.453l-5 14.225H0V1a1 1 0 011-1h48a1 1 0 011 1v17.225zm0 3.554V43a1 1 0 01-1 1H1a1 1 0 01-1-1V21.78h16.157l3.524-10.03L25.364 36h2.457l5-14.22H50z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 44"
    }
  };
});