define("ember-svg-jar/inlined/icn-menu-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M50 41.78V8.18c0-.86-.38-1.64-1.12-2.21-.67-.57-1.64-.79-2.46-.57L33.13 8.11l-14.1-5.43c-.16-.08-.34-.13-.52-.14h-.08c-.23 0-.38-.07-.6 0h-.08c-.23 0-.37.07-.6.07h-.07L2.16 6.9C.9 7.25 0 8.4 0 9.61v33.6c0 .93.45 1.79 1.19 2.29a3.1 3.1 0 001.79.57c.3 0 .6-.08.82-.15l13.95-4.01 13.96 5.36h.07c.15.07.23.07.38.07.07 0 .07 0 .15.07.15 0 .38.07.52.07.15 0 .37 0 .52-.07h.08l14.18-2.86c1.42-.27 2.39-1.41 2.39-2.77zM5.97 11.75l8.95-2.58v27.6l-8.95 2.58v-27.6zM20.9 9.54l8.95 3.43v27.45l-8.95-3.43V9.54zm23.13 29.95l-8.21 1.65V13.33l8.21-1.64v27.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});