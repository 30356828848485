define("ember-svg-jar/inlined/icn-out-of-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#B9B5AE\" d=\"M5.775 11.227c0 1.495 1.298 2.737 2.914 2.737h6.887v4.106c0 .608-.53 1.115-1.165 1.115H1.298c-.636 0-1.166-.507-1.166-1.115V1.19C.132.583.662.076 1.298.076h13.06c.635 0 1.165.507 1.165 1.115v4.106H8.636c-1.563 0-2.861 1.216-2.861 2.737v3.193zm19.205-2.23c.424.33.424.938 0 1.292l-6.066 4.714c-.556.406-1.378.05-1.378-.633v-2.307h-8.9c-.477 0-.848-.354-.848-.81V8.058c0-.456.371-.81.848-.81h8.9V4.941c0-.684.822-1.064 1.378-.634l6.066 4.689z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "26",
      "height": "20",
      "viewBox": "0 0 26 20"
    }
  };
});