define("ember-svg-jar/inlined/icn-sortable-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#DCDBD9\" fill-rule=\"evenodd\" d=\"M2.324 0h17.102a1.45 1.45 0 010 2.899H2.324a1.45 1.45 0 010-2.899zm0 5.072h17.102a1.45 1.45 0 110 2.899H2.324a1.45 1.45 0 110-2.899zm0 5.073h17.102a1.45 1.45 0 110 2.898H2.324a1.45 1.45 0 110-2.898z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 21 14"
    }
  };
});