define("ember-svg-jar/inlined/icn-menu-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M25.07 8C11.32 8 .13 19.27.13 33.09c0 2.49.36 4.88 1.03 7.16A2.418 2.418 0 003.48 42h43.18c1.07 0 2.01-.71 2.31-1.75A24.94 24.94 0 0050 33.09C50 19.27 38.81 8 25.07 8zm-2.42 5.01v.66c0 1.34 1.08 2.43 2.41 2.43s2.41-1.09 2.41-2.43v-.66c3.65.43 7.12 1.87 10.01 4.15l-.48.48a2.45 2.45 0 000 3.44c.94.94 2.46.95 3.41.01l.01-.01.48-.48c2.27 2.92 3.7 6.4 4.13 10.07h-.66c-1.33 0-2.41 1.09-2.41 2.43s1.08 2.43 2.41 2.43H45c-.07.54-.17 1.09-.28 1.62H5.41c-.11-.54-.2-1.08-.28-1.62h.63c1.33 0 2.41-1.09 2.41-2.43s-1.08-2.43-2.41-2.43h-.65C5.54 27 6.97 23.51 9.23 20.6l.48.48c.94.94 2.46.95 3.41.01l.01-.01c.94-.95.94-2.49 0-3.44l-.48-.48c2.89-2.28 6.35-3.72 10-4.15zm10.94 9.89l-7.67 4.63c-1.34-.29-2.73.12-3.69 1.09a4.062 4.062 0 000 5.72 3.996 3.996 0 005.65.03l.03-.03c.96-.98 1.37-2.38 1.08-3.72l4.6-7.72z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});