define("ember-svg-jar/inlined/icn-sensor-water-tension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.2 20.78c-3.35-1.44-5.97-2.21-8.5-2.34v-4.36h1.22c3.63 0 6.58-3 6.58-6.69v-.6h-3.17c-2.02 0-3.78 1.07-4.8 2.68-.04-.04-.09-.06-.13-.09V8.29c-.02-3.2-2.58-5.79-5.73-5.79H6.5v.6c0 3.69 4.37 6.9 8 6.9v8.43c-2.48.08-4.97.77-8.09 2.1-.3.13-.45.48-.32.79.13.3.48.45.79.32 2.97-1.26 5.31-1.93 7.62-2V27c0 .33.27.6.6.6s.6-.27.6-.6v-7.36c2.37.13 4.83.87 8.02 2.24.08.03.16.05.24.05.23 0 .45-.14.55-.36a.602.602 0 00-.31-.79z\"/><path d=\"M10.78 23.22c-.38-.38-.94.19-.57.57l.7.71c.12.12.08.22-.09.22H8.9c-.22 0-.4.18-.4.4 0 .22.18.4.4.4h1.94c.16 0 .2.1.09.22l-.7.71c-.38.38.19.95.57.57l1.62-1.66a.36.36 0 000-.5l-1.64-1.64zm10.32 1.5h-1.94c-.16 0-.2-.1-.09-.22l.7-.71c.38-.38-.19-.95-.57-.57l-1.62 1.66a.36.36 0 000 .5l1.61 1.65c.38.38.94-.19.57-.57l-.7-.71c-.12-.12-.08-.21.09-.21h1.94c.22 0 .4-.18.4-.4a.392.392 0 00-.39-.42z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});