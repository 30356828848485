define("ember-svg-jar/inlined/icn-sensor-tension-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.06 10.89H16V6.63c0-.35.26-.63.59-.63.32 0 .59.28.59.63v3h2.08c.32 0 .59.28.59.63s-.26.63-.59.63h.8z\"/><path d=\"M23 10.24c0 3.65-2.92 6.61-6.5 6.61S10 13.88 10 10.24c0-3.02 2-5.57 4.72-6.36V3h3.49v.86C20.97 4.63 23 7.2 23 10.24zm-1.25 0c0-2.94-2.35-5.34-5.25-5.34s-5.25 2.4-5.25 5.34 2.35 5.34 5.25 5.34 5.25-2.4 5.25-5.34zM19.8 23.48c.27-.27.27-.72 0-.99l-3.23-3.24c-.75-.75-1.88.38-1.13 1.13l1.41 1.4c.23.23.16.42-.17.42H12.8c-.44 0-.8.36-.8.8 0 .44.36.8.8.8h3.87c.33 0 .41.19.17.42l-1.41 1.4c-.75.75.38 1.88 1.13 1.13l3.24-3.27zM5.2 18.52c-.27.27-.27.72 0 .99l3.23 3.24c.75.75 1.88-.38 1.13-1.13l-1.41-1.4c-.23-.23-.15-.42.18-.42h3.87c.44 0 .8-.36.8-.8 0-.44-.36-.8-.8-.8H8.33c-.33 0-.41-.19-.17-.42l1.41-1.4c.75-.75-.38-1.88-1.13-1.13L5.2 18.52z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});