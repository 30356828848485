define("ember-svg-jar/inlined/icn-empty-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#c6d3da\" fill-rule=\"evenodd\" d=\"M0 27.5C0 12.312 12.312 0 27.5 0S55 12.312 55 27.5 42.688 55 27.5 55H0V27.5zm27.408 7.537a2.05 2.05 0 00-2.071 2.072c0 1.16.91 2.071 2.071 2.071a2.05 2.05 0 002.072-2.071c0-1.16-.911-2.072-2.072-2.072zm0-18.644a2.072 2.072 0 00-2.071 2.072v12.43a2.072 2.072 0 004.143 0v-12.43a2.072 2.072 0 00-2.072-2.072z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 55 55"
    }
  };
});