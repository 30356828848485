define("ember-svg-jar/inlined/icn-sensor-pressure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M28.011 19.99v4.177h-5.88V19.99h5.88zM9.901 9.487c0 3.008 2.23 4.908 5.099 4.908 2.927 0 5.1-1.947 5.1-4.908 0-2.753-2.283-4.984-5.1-4.984-2.817 0-5.1 2.231-5.1 4.985zm4.979-.853l-2.577-2.972 1.959 3.39c-.377.72.492 1.47 1.178 1.005.691-.472.262-1.522-.56-1.423zm3.93 18.156h-7.62c-1.283 0-2.33-1.024-2.33-2.277v-4.876c0-1.253 1.047-2.277 2.33-2.277h1.151v-2.17c-2.235-.9-3.77-2.96-3.77-5.704 0-3.468 2.88-6.278 6.43-6.278s6.43 2.81 6.43 6.278c0 2.495-1.262 4.447-3.205 5.456v2.423h.592c1.282 0 2.33 1.024 2.33 2.277v4.876c-.005 1.248-1.052 2.272-2.335 2.272zm-10.94-6.8v4.177H1.99V19.99h5.88z\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});