define("ember-svg-jar/inlined/icn-sensor-rst-rs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M15.08 24.36c-4 0-7.22-3.19-7.22-7.15 0-1.33.37-2.59 1.02-3.64.12-.24.29-.45.45-.65l.04-.04c.08-.12.16-.2.25-.28l5.46-6.79 5.5 6.75c.04.04.08.12.12.16l.12.16c.9 1.21 1.47 2.71 1.47 4.33.01 3.91-3.21 7.15-7.21 7.15z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});