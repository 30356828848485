define("ember-svg-jar/inlined/icn-global-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#F07048\" fill-rule=\"evenodd\" d=\"M24.7 12.35c0 6.82-5.53 12.35-12.35 12.35C5.53 24.7 0 19.17 0 12.35 0 5.53 5.53 0 12.35 0H24.7v12.35zm-10.95 1.54l.453-8.682h-3.075l.453 8.682h2.169zm-2.657 3.04c0 .266.04.494.12.683a1.27 1.27 0 00.83.743c.19.056.394.084.614.084.207 0 .404-.028.59-.084a1.294 1.294 0 00.831-.742c.083-.19.125-.418.125-.685a1.78 1.78 0 00-.125-.706 1.225 1.225 0 00-.83-.725 2.141 2.141 0 00-.591-.08c-.22 0-.424.027-.614.08-.19.054-.355.14-.497.258a1.186 1.186 0 00-.334.467 1.84 1.84 0 00-.12.706z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
});