define("ember-svg-jar/inlined/icn-temp-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#C6D3DA\" fill-rule=\"evenodd\"><path d=\"M26.462 11.326C26.462 5.06 21.766 0 15.877 0 9.987 0 5.29 5.06 5.29 11.326v27.195C1.985 41.693 0 45.91 0 50.527c0 9.17 7.368 16.842 16.06 16.842 8.643 0 15.694-7.623 15.694-16.842 0-4.619-1.985-8.834-5.292-12.006V11.326zm-5.294 28.498c0 .863.375 1.68 1.015 2.21 2.704 2.246 4.28 5.267 4.28 8.493 0 6.142-4.704 11.226-10.403 11.226-5.79 0-10.769-5.179-10.769-11.226 0-3.227 1.576-6.248 4.28-8.492a2.874 2.874 0 001.014-2.21v-28.5c0-3.184 2.345-5.713 5.292-5.713 2.947 0 5.291 2.529 5.291 5.713v28.498z\"/><path d=\"M18.698 42.986v-5.884c0-1.331-.726-2.692-2.737-2.692-2.01 0-2.737 1.108-2.737 2.692v5.884c-3.18 1.112-5.47 4.074-5.47 7.587 0 4.462 3.675 8.082 8.207 8.082 4.532 0 8.207-3.62 8.207-8.082 0-3.513-2.29-6.476-5.47-7.587\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 68"
    }
  };
});