define("ember-svg-jar/inlined/icn-drop-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#98948E\" fill-rule=\"evenodd\" d=\"M7.9 0c-.325 0-.635.114-.885.357l-5.75 5.574a1.19 1.19 0 000 1.715 1.282 1.282 0 001.769 0L7.899 2.93l4.866 4.716a1.283 1.283 0 001.77 0 1.19 1.19 0 000-1.715L8.784.357A1.25 1.25 0 007.898 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "8",
      "viewBox": "0 0 15 8"
    }
  };
});