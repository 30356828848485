define("ember-svg-jar/inlined/icn-graph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#BBB8B3\" fill-rule=\"evenodd\"><path d=\"M62.858 61.715H2.285v-5.142h2.857a1.142 1.142 0 100-2.285l-2.857-.003v-4.572h2.857a1.142 1.142 0 100-2.285H2.285v-4.573h2.857a1.142 1.142 0 100-2.285l-2.857.003V36h2.857a1.142 1.142 0 100-2.285H2.285v-4.572h2.857a1.142 1.142 0 100-2.286H2.285v-4.572h2.857a1.142 1.142 0 100-2.285H2.285v-4.573h2.857a1.142 1.142 0 100-2.285H2.285V8.57h2.857a1.142 1.142 0 100-2.285H2.285V1.142a1.142 1.142 0 10-2.285 0v61.716C0 63.49.512 64 1.142 64h61.715a1.142 1.142 0 100-2.285z\"/><path d=\"M20.545 37.985c1.79-7.877 3.82-16.802 7.455-16.802 3.16 0 5.638 6.542 7.812 13.385-1.73 2.367-3.03 5.635-4.302 8.852-2.12 5.36-4.31 10.903-8.08 10.903a1.142 1.142 0 100 2.285c5.327 0 7.807-6.278 10.207-12.35.96-2.428 1.933-4.885 3.063-6.856l.212.685C39.84 47.615 42.607 56.612 48 56.612a1.142 1.142 0 100-2.285c-3.705 0-6.468-8.982-8.908-16.907-.242-.793-.487-1.575-.73-2.355.975-1.053 2.07-1.703 3.35-1.703 3.773 0 5.963 5.543 8.08 10.903 2.4 6.07 4.88 12.347 10.205 12.347a1.142 1.142 0 100-2.285c-3.77 0-5.96-5.542-8.08-10.9-1.004-2.539-2.022-5.114-3.256-7.252-1.717-2.973-3.852-5.098-6.951-5.098-1.61 0-2.958.583-4.125 1.55-2.453-7.542-5.12-13.73-9.59-13.73-5.46 0-7.512 9.025-9.685 18.583-1.785 7.877-3.815 16.805-7.453 16.805a1.144 1.144 0 000 2.288c5.463 0 7.515-9.027 9.688-18.588z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 64"
    }
  };
});