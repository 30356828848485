define("ember-svg-jar/inlined/icn-sensor-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#98948E\" fill-rule=\"evenodd\" d=\"M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0h10v10zm-8.867 1.247l.367-7.03H9.01l.368 7.03h1.755zm-2.151 2.46c0 .217.032.401.097.555a1.03 1.03 0 00.673.6 1.718 1.718 0 00.975 0c.15-.045.284-.118.4-.219.114-.1.205-.228.273-.381.067-.154.1-.338.1-.554 0-.226-.033-.416-.1-.572a.992.992 0 00-.673-.587 1.734 1.734 0 00-.479-.064 1.83 1.83 0 00-.496.064 1.076 1.076 0 00-.403.209.96.96 0 00-.27.378 1.493 1.493 0 00-.097.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
});