define("ember-svg-jar/inlined/icn-drop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.608 20.76a7.314 7.314 0 01-7.328-7.328 7.09 7.09 0 011.035-3.726c.125-.25.29-.456.456-.663L2.813 9c.083-.125.165-.207.25-.29l5.545-6.956 5.59 6.914c.042.042.082.124.124.165l.125.165c.91 1.242 1.491 2.774 1.491 4.43-.002 4.019-3.272 7.33-7.33 7.33v.001z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "viewBox": "0 0 17 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});