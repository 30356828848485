define("ember-svg-jar/inlined/icn-cpu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.691 19.192h8.501v-8.5h-8.5v8.5zm2.33-6.172h3.842v3.872H13.02V13.02z\"/><path d=\"M24.17 5.713h-4.657V3.5h-2.33v2.213H12.73V3.5H10.4v2.213H5.742v4.658H3.5V12.7h2.213v3.494H3.5v2.329h2.213v5.619h4.658v2.212H12.7v-2.212h4.454v2.212h2.33v-2.212h4.658v-5.59h2.212v-2.33h-2.212V12.73h2.212V10.4h-2.212V5.713h.029zm-2.328 4.658v11.442h-13.8V8.042h13.77v2.329h.03z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30"
    }
  };
});