define("ember-collection/layouts/grid", ["exports", "layout-bin-packer/fixed-grid", "ember-collection/utils/style-generators"], function (_exports, _fixedGrid, _styleGenerators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Grid {
    constructor(cellWidth, cellHeight) {
      this.length = 0;
      this.bin = new _fixedGrid.default(this, cellWidth, cellHeight);
    }
    contentSize(clientWidth /*, clientHeight*/) {
      return {
        width: clientWidth,
        height: this.bin.height(clientWidth)
      };
    }
    indexAt(offsetX, offsetY, width, height) {
      return this.bin.visibleStartingIndex(offsetY, width, height);
    }
    positionAt(index, width /*,height*/) {
      return this.bin.position(index, width);
    }
    widthAt(index) {
      return this.bin.widthAtIndex(index);
    }
    heightAt(index) {
      return this.bin.heightAtIndex(index);
    }
    count(offsetX, offsetY, width, height) {
      return this.bin.numberVisibleWithin(offsetY, width, height, true);
    }
    formatItemStyle(itemIndex, clientWidth, clientHeight) {
      let pos = this.positionAt(itemIndex, clientWidth, clientHeight);
      let width = this.widthAt(itemIndex, clientWidth, clientHeight);
      let height = this.heightAt(itemIndex, clientWidth, clientHeight);
      return (0, _styleGenerators.formatPixelStyle)(pos, width, height);
    }
  }
  _exports.default = Grid;
});