define("ember-svg-jar/inlined/icn-sensor-chill-portions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.24 13.25c-1.43-1.31-3.37-2.71-4.75-3.67-.09-.07-.14-.14-.15-.26-.03-.23.1-.41.33-.44.08-.01.2.01.28.04l.04-.01c1.94 1.36 3.5 2.53 4.66 3.57.05-.17.11-.33.16-.5 0 0-.01-.04.03-.08.02-.12.04-.28.06-.41.06-.36.02-.77-.04-1.13a4.14 4.14 0 00-.33-1.14c-.33-.73-.88-1.32-1.54-1.76-.69-.46-1.49-.74-2.31-.89-.85-.16-1.73-.17-2.58-.06-2.71.34-4.3-.19-4.3-.19s.94 3.93 2.05 6.04c1.27 2.41 3.09 3.19 5.41 2.86.15-.02.3-.04.45-.1l.04-.01c.11-.02.26-.08.37-.13l.04-.01c.26-.12.51-.23.76-.39.21-.15.42-.3.63-.49.89.79 1.48 1.42 1.53 1.74l1.14-.16c-.13-.57-.92-1.49-1.98-2.42zM4.13 19.86c.11 0 .21-.03.31-.08l2.1-1.17v1.76c0 .33.28.6.63.6s.63-.27.63-.6v-2.46l3.61-2v4.04l-2.16 1.2c-.3.17-.4.54-.23.82.12.19.33.3.54.3.11 0 .21-.03.31-.08l1.53-.85v2.3c0 .33.28.6.63.6s.63-.27.63-.6v-2.3l1.53.85c.1.05.21.08.31.08.22 0 .43-.11.54-.3.17-.29.07-.66-.23-.82l-2.16-1.2v-4.04l3.63 2.02v2.45c0 .33.28.6.63.6s.63-.27.63-.6v-1.75l2.07 1.15c.1.05.21.08.31.08.22 0 .43-.11.54-.3.17-.29.07-.66-.23-.82l-2.03-1.12 1.48-.82c.3-.17.4-.54.23-.82a.64.64 0 00-.86-.22l-2.11 1.17-3.68-2.04 3.63-1.85-.58-1.04-3.65 1.83v-3.68l2.16-1.2c.3-.17.4-.54.23-.82a.64.64 0 00-.86-.22l-1.53.85V6.1c0-.33-.28-.6-.63-.6s-.63.27-.63.6v2.66l-1.53-.85a.642.642 0 00-.86.22c-.17.29-.07.66.23.82l2.16 1.2v3.68l-3.61-2v-2.1c0-.33-.28-.6-.63-.6s-.63.27-.63.6v1.4l-2.1-1.17a.642.642 0 00-.86.22c-.17.29-.07.66.23.82l2.36 1.31-1.79 1c-.3.17-.4.54-.23.82.12.19.33.3.54.3.11 0 .21-.03.31-.08l2.42-1.34 3.34 1.85-3.67 2.03-2.1-1.16a.642.642 0 00-.86.22c-.17.29-.07.66.23.82l1.47.82-2.04 1.13c-.3.17-.4.54-.23.82.15.21.36.32.58.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});