define("ember-svg-jar/inlined/icn-cloud-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#BBB8B3\" fill-rule=\"evenodd\"><path d=\"M54.302 20.126h-2.7c.5-2.5.5-5.2-.199-7.899-1.8-6.5-7.402-11.3-14.102-12.102-9.101-1.101-17 5.2-18.5 13.602h-4.199c-8 0-14.602 6.5-14.602 14.602 0 8 6.5 14.602 14.602 14.602h5.2c.398 0 .8-.199 1.1-.5.7-.898.102-2.3-1.1-2.3h-4.9c-6.1 0-11.5-4.5-12.101-10.603-.7-7 4.898-12.898 11.699-12.898l6.5-.003.3-1.899c1.2-6.398 6.4-11.398 12.9-11.8 8-.5 14.699 6 14.699 13.897 0 1-.102 1.899-.301 2.899l-.496 3.203h6.3c4.5 0 8.5 3.398 8.801 8 .301 5-3.601 9.101-8.5 9.101h-8.398c-1.2 0-1.8 1.301-1.102 2.301.301.3.7.5 1.102.5h8.398c6.301 0 11.5-5.2 11.301-11.602-.105-6.2-5.504-11.102-11.703-11.102h.002z\"/><path d=\"M33 32c-4.5 0-9 1.301-9 4.102V46.5c0 2.899 4.5 4.102 9 4.102s9-1.301 9-4.102V36.2c0-2.903-4.5-4.2-9-4.2zm0 3c4.2 0 5.8 1.102 5.8 1.102-.698.5-2.698 1.102-5.8 1.102s-5.102-.7-5.8-1.102c0 .098 1.6-1.101 5.8-1.101zm6 11.4c-.5.5-2.602 1.3-6 1.3s-5.5-.8-6-1.3v-1.602c1.7.7 4 .898 6 .898 1.898 0 4.2-.3 6-.898V46.4zm0-5c-.7.601-3 1.3-6 1.3-3.5 0-5.602-.8-6-1.3v-2c1.7.601 3.8.898 6 .898s4.3-.3 6-.898v2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 66 51"
    }
  };
});