define("ember-svg-jar/inlined/icn-menu-flow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M49.83 34.09v8.07H38.6v-8.07h11.23zM15.24 13.8c0 5.81 4.26 9.48 9.74 9.48 5.59 0 9.74-3.76 9.74-9.48 0-5.32-4.36-9.63-9.74-9.63s-9.74 4.31-9.74 9.63zm9.51-1.65l-4.92-5.74 3.74 6.55c-.72 1.39.94 2.84 2.25 1.94 1.32-.91.5-2.94-1.07-2.75zm7.51 35.08H17.7c-2.45 0-4.45-1.98-4.45-4.4v-9.42c0-2.42 2-4.4 4.45-4.4h2.2v-4.19c-4.27-1.74-7.2-5.72-7.2-11.02 0-6.7 5.5-12.13 12.28-12.13 6.78 0 12.28 5.43 12.28 12.13 0 4.82-2.41 8.59-6.12 10.54v4.68h1.13c2.45 0 4.45 1.98 4.45 4.4v9.42c-.01 2.41-2.01 4.39-4.46 4.39zm-20.9-13.14v8.07H.13v-8.07h11.23z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});