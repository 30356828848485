define("ember-collection/utils/identity", ["exports", "@ember/object/internals"], function (_exports, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = identity;
  function identity(item) {
    let key;
    let type = typeof item;
    if (type === 'string' || type === 'number') {
      key = item;
    } else {
      key = (0, _internals.guidFor)(item);
    }
    return key;
  }
});