define("ember-svg-jar/inlined/icn-sensor-web-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M24.81 14.25v1.22h-4.88c-.85-1.22-2.2-1.95-3.66-2.32V9.37h2.81c.37.73 1.22 1.22 2.07 1.22 1.34 0 2.44-1.1 2.44-2.44s-1.1-2.44-2.44-2.44c-.85 0-1.71.49-2.07 1.22h-8.06c-.37-.74-1.23-1.23-2.08-1.23-1.35 0-2.44 1.1-2.44 2.44s1.1 2.44 2.44 2.44c.85 0 1.71-.49 2.07-1.22h2.81v3.78c-1.46.37-2.81 1.1-3.66 2.32H5.28v-1.22H2.83v9.77h2.44v-1.22h4.88c1.1 1.46 2.81 2.44 4.88 2.44 1.95 0 3.78-.98 4.88-2.44h4.88v1.22h2.44v-9.77l-2.42.01z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});