define("ember-svg-jar/inlined/icn-live-weather-atmospheric-pressure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.6 8.8c0-.3-.3-.4-.5-.4s-.4.2-.4.5c.1.6.1 1.2 0 1.8-.1 1.1-.3 1.9-1 3-.9 1.5-1.2 3.4-1.2 4.8v1.2c-.19-.19-.37-.44-.66-.45a.427.427 0 00-.35.14c-.07.09-.1.22-.08.33.04.2.19.28.32.41l.34.34.53.53c.1.1.2.1.3.1.1 0 .2 0 .3-.1l1.1-1.1c.15-.15.18-.4.05-.57-.11-.14-.28-.15-.45-.13-.02 0-.03 0-.05.01-.01.01-.03.01-.04.02-.16.09-.28.24-.41.37v-1.1c0-.6.1-1.2.2-1.9.2-1.2.59-2 1.19-3 .7-1.2 1.01-2.7.81-4.8zm3.77 0c0-.3-.3-.4-.5-.4s-.4.2-.4.5c.1.6.1 1.2 0 1.8-.1 1.1-.3 1.9-1 3-.9 1.5-1.2 3.4-1.2 4.8v1.2c-.19-.19-.37-.44-.66-.45a.427.427 0 00-.35.14c-.07.09-.1.22-.08.33.04.2.19.28.32.41l.34.34.52.52c.1.1.2.1.3.1s.2 0 .3-.1l1.1-1.1c.15-.15.18-.4.05-.57-.11-.14-.28-.15-.45-.13-.02 0-.03 0-.05.01-.01.01-.03.01-.04.02-.16.09-.28.24-.41.37v-1.1c0-.6.1-1.2.2-1.9.2-1.2.59-2 1.19-3 .7-1.19 1.02-2.69.82-4.79zm3.76 0c0-.3-.3-.4-.5-.4s-.4.2-.4.5c.1.6.1 1.2 0 1.8-.1 1.1-.3 1.9-1 3-.9 1.5-1.2 3.4-1.2 4.8v1.2c-.19-.19-.37-.44-.66-.45a.427.427 0 00-.35.14c-.07.09-.1.22-.08.33.04.2.19.28.32.41l.34.34.52.52c.1.1.2.1.3.1s.2 0 .3-.1l1.1-1.1c.15-.15.18-.4.05-.57-.11-.14-.28-.15-.45-.13-.02 0-.03 0-.05.01-.01.01-.03.01-.04.02-.16.09-.28.24-.41.37v-1.1c0-.6.1-1.2.2-1.9.2-1.2.59-2 1.19-3 .71-1.19 1.02-2.69.82-4.79zm5.77 6V6.5c0-.3-.2-.5-.5-.5h-3.5c-.3 0-.5.2-.5.5s.2.5.5.5h2.9v1.8h-1.6c-.3 0-.5.2-.5.5s.2.5.5.5h1.6v1.8h-1.6c-.3 0-.5.2-.5.5s.2.5.5.5h1.6v1.8h-2.9c-.3 0-.5.2-.5.5s.2.5.5.5h2.9v1.8h-1.6c-.3 0-.5.2-.5.5s.2.5.5.5h1.6V20h-1.6c-.3 0-.5.2-.5.5s.2.5.5.5h1.6v1.8h-2.9c-.3 0-.5.2-.5.5s.2.5.5.5h3.7c.2-.1.3-.2.3-.4V15\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});