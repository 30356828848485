define("ember-svg-jar/inlined/icn-device-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#7ecaab\" fill-rule=\"evenodd\" d=\"M22 8.223h-8.06l-1.56 4.388L9.86 2H8.775L6.56 8.224H0V1a1 1 0 011-1h20a1 1 0 011 1v7.223zm0 1.555V18a1 1 0 01-1 1H1a1 1 0 01-1-1V9.778h7.584l1.56-4.387L11.661 16h1.088l2.215-6.222H22z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 19"
    }
  };
});