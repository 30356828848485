define("ember-svg-jar/inlined/icn-single-irrigation-autom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M56.827 34.947l-5.383-2.28a17.325 17.325 0 00-1.666-4.056l2.18-5.315a1.924 1.924 0 00-.419-2.09l-1.8-1.8a1.924 1.924 0 00-2.09-.42l-5.316 2.18a17.302 17.302 0 00-4.055-1.666l-2.229-5.32A1.922 1.922 0 0034.276 13h-2.558c-.772 0-1.47.462-1.77 1.173l-2.281 5.383a17.322 17.322 0 00-4.056 1.666l-5.315-2.18a1.924 1.924 0 00-2.09.419l-1.8 1.8a1.924 1.924 0 00-.42 2.09l2.18 5.316a17.302 17.302 0 00-1.666 4.055l-5.32 2.229A1.922 1.922 0 008 36.724v2.558c0 .772.462 1.47 1.173 1.77l5.383 2.281a17.322 17.322 0 001.666 4.056l-2.18 5.315a1.924 1.924 0 00.419 2.09l1.8 1.8a1.924 1.924 0 002.09.42l5.316-2.18a17.302 17.302 0 004.055 1.666l2.229 5.32A1.92 1.92 0 0031.724 63h2.558c.772 0 1.47-.462 1.77-1.173l2.281-5.383a17.324 17.324 0 004.056-1.666l5.315 2.18a1.924 1.924 0 002.09-.419l1.8-1.8a1.924 1.924 0 00.42-2.09l-2.18-5.316a17.302 17.302 0 001.666-4.055l5.32-2.229A1.922 1.922 0 0058 39.276v-2.558c0-.772-.462-1.47-1.173-1.77zM33 47.167A9.166 9.166 0 0123.833 38 9.166 9.166 0 0133 28.833 9.166 9.166 0 0142.167 38 9.166 9.166 0 0133 47.167z\" fill=\"#2449E3\"/>",
    "attrs": {
      "width": "66",
      "height": "76",
      "viewBox": "0 0 66 76",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});