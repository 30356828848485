define("ember-svg-jar/inlined/icn-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#6ED191\" fill-rule=\"evenodd\" stroke=\"#6ED191\" stroke-width=\".8\" d=\"M3.844 8.776a.894.894 0 001.247-.061l5.72-6.45a.734.734 0 00-.122-1.09.895.895 0 00-1.186.11L4.379 7.08 2.437 5.317a.897.897 0 00-1.193.006.732.732 0 00.007 1.096l2.593 2.357\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 12 10"
    }
  };
});