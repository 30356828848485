define("ember-svg-jar/inlined/icn-pest-and-disease", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"23.6\" cy=\"6.9\" r=\"1.5\"/><circle cx=\"11.5\" cy=\"17.3\" r=\"5\"/><circle cx=\"11.5\" cy=\"11\" r=\"2.9\"/><circle cx=\"19.5\" cy=\"23\" r=\"1\"/><circle cx=\"20.8\" cy=\"12.9\" r=\"1\"/><circle cx=\"21.3\" cy=\"15.1\" r=\"2.3\"/><circle cx=\"5.5\" cy=\"13.1\" r=\"1.3\"/><circle cx=\"15.5\" cy=\"23.4\" r=\"1.3\"/><circle cx=\"16.4\" cy=\"8\" r=\"1.3\"/><circle cx=\"24.2\" cy=\"19.1\" r=\"1.3\"/><circle cx=\"6.6\" cy=\"22.5\" r=\"1.3\"/><circle cx=\"17.1\" cy=\"12.3\" r=\"1.3\"/><circle cx=\"19.4\" cy=\"20.7\" r=\"2.4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});