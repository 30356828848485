define("ember-svg-jar/inlined/icn-sensor-total-volume", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24 5H6a.94.94 0 00-.9 1.3L6 9.2V22a3 3 0 003 3h12a3 3 0 003-3V9.2l.9-2.8A1 1 0 0024 5zm-2 17a.94.94 0 01-1 1H9a.94.94 0 01-1-1v-3h14zm.1-13.3a.37.37 0 00-.1.3v8H8V9a.37.37 0 00-.1-.3L7.4 7h15.2z\"/><path d=\"M20.1 23.9H9.6a2.8 2.8 0 01-2.8-2.8v-.7a2.8 2.8 0 012.8-2.8H20a2.8 2.8 0 012.8 2.8v.7a2.65 2.65 0 01-2.7 2.8z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});