define("ember-svg-jar/inlined/icn-notification-custom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M29.156 26.748l-1.678-2.606V17.1c0-3.913-2.79-7.148-6.42-7.68V8.321c-.002-.607-.474-1.098-1.058-1.098-.585 0-1.057.491-1.057 1.098v1.097c-3.632.534-6.423 3.768-6.423 7.68v7.043l-1.677 2.606a1.15 1.15 0 00.006 1.094c.175.332.519.541.894.545h16.514c.376-.005.72-.214.894-.546a1.16 1.16 0 00.005-1.093zM20 32.776c1.327 0 2.471-1.295 2.961-3.136h-5.923c.49 1.842 1.634 3.136 2.962 3.136z\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});