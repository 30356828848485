define("ember-svg-jar/inlined/icn-notification-snooze", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.56.961c-.46-.175-.986.022-1.178.441-.192.418.024.899.483 1.075.997.448 1.734 1.265 2.025 2.243.172.386.632.594 1.073.483.44-.112.72-.505.65-.917-.438-1.46-1.552-2.676-3.052-3.325zM2.264 5.232c.405 0 .757-.243.864-.594.289-.957 1.022-1.756 2.012-2.194.455-.173.67-.644.479-1.053-.19-.41-.715-.603-1.17-.432-1.495.636-2.604 1.825-3.041 3.257a.74.74 0 00.153.699.931.931 0 00.709.316l-.007.002h.001zM12.6 9.385h-1.763V6.19c0-.53-.38-.958-.85-.958-.469 0-.85.429-.85.958v4.152c0 .255.09.499.25.678.15.175.37.278.6.28H12.6c.47 0 .85-.428.85-.958 0-.528-.38-.958-.85-.958v.001z\"/><path d=\"M17.762 9.53v-.003c0-4.257-3.504-7.76-7.761-7.76s-7.76 3.503-7.76 7.76c0 2.3 1.022 4.485 2.788 5.958a.843.843 0 00-.334.269l-1.457 1.98a.865.865 0 00-.166.507c0 .474.389.863.862.863a.863.863 0 00.697-.355l1.457-1.98a.864.864 0 00.162-.451 7.739 7.739 0 007.517 0c.01.163.067.32.163.45l1.439 1.981a.861.861 0 101.393-1.016l-1.457-1.983a.858.858 0 00-.334-.269 7.751 7.751 0 002.79-5.95zm-13.806 0v-.004c0-3.314 2.728-6.042 6.042-6.042 1.603 0 3.143.639 4.276 1.773a6.047 6.047 0 011.835 4.337c0 3.315-2.728 6.043-6.043 6.043-1.634 0-3.2-.663-4.338-1.836A6.053 6.053 0 013.955 9.53h.001z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});