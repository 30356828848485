define("ember-svg-jar/inlined/icn-manual-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M11.498 28.25A10.873 10.873 0 01.606 17.358c0-2.032.553-3.938 1.538-5.54.185-.37.43-.677.678-.985l.063-.063a2.51 2.51 0 01.37-.43L11.499 0l8.308 10.277c.063.063.123.185.186.246l.185.245c1.353 1.846 2.216 4.123 2.216 6.584-.002 5.975-4.863 10.898-10.895 10.898z\" fill=\"#00A2E1\"/><path d=\"M10.766 12.767v6.14m3.334 3.304l-3.334-3.175\" stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"3\"/></g>",
    "attrs": {
      "viewBox": "0 0 23 29",
      "width": "23",
      "height": "29",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});