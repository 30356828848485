define("ember-svg-jar/inlined/icn-farm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.671 16.825c.791-.269 1.329-1.037 1.329-1.897 0-1.091-.865-1.991-1.922-1.998a1.9 1.9 0 00-1.515.768 1.9 1.9 0 00-1.515-.768 1.9 1.9 0 00-1.515.768 1.9 1.9 0 00-1.515-.768c-1.061 0-1.926.896-1.926 1.998 0 .886.561 1.638 1.334 1.898v.944h-1.633l-.001-8.392h.102a.606.606 0 00.593-.614v-.001c0-4.156-3.25-7.537-7.244-7.537-.642 0-1.265.089-1.858.253V.614A.607.607 0 004.793 0H2.027a.607.607 0 00-.592.615v3.65A7.703 7.703 0 000 8.763c0 .339.265.615.592.615h.104v9.007c0 .339.265.615.592.615h22.214v-1.23h-.831v-.945zm-3.03 0c.365-.123.686-.356.922-.67.235.31.554.546.923.67v.944h-1.845v-.944zm-3.03 0c.365-.123.686-.356.921-.67.235.31.554.546.923.67v.944H16.61v-.944h.001zM4.544 12.676l1.886 2.08-1.886 2.079v-4.159zm.778-.936h3.843l-1.921 2.119-1.922-2.119zm4.621.936v4.161l-1.886-2.08 1.886-2.081zm-2.7 2.976l1.923 2.119H5.323l1.92-2.119zM4.199 1.229v.698a7.242 7.242 0 00-1.58 1.039V1.229h1.58zm3.044 1.226c3.143 0 5.733 2.503 6.032 5.694H1.212c.299-3.191 2.889-5.694 6.032-5.694h-.001z\" fill=\"#bbb8b3\"/>",
    "attrs": {
      "viewBox": "0 0 24 19",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});