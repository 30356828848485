define("ember-svg-jar/inlined/icn-leaf-tension-high", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M39.811 16.436a.744.744 0 00-.003-.998l-3.067-3.264c-.716-.756-1.789.378-1.074 1.135l1.338 1.414c.223.236.147.427-.164.427h-3.676c-.418 0-.759.359-.759.801 0 .445.341.803.759.803h3.676c.313 0 .388.191.164.428l-1.338 1.413c-.715.757.358 1.892 1.074 1.135l3.071-3.293-.001-.001zM27.24 15.125c-2.178-2.724-5.24-5.734-7.417-7.812a.665.665 0 01-.204-.502c0-.43.272-.715.68-.715.137 0 .341.07.475.143h.069c3.062 2.939 5.511 5.447 7.281 7.597.137-.286.272-.574.408-.86 0 0 0-.071.068-.144.068-.214.137-.501.204-.715 0-.072.068-.144.068-.216.067-.215.067-.43.137-.646 0-.069 0-.143.067-.285-.068-.289-.068-.648-.068-.934 0-5.591-5.579-8.53-10.887-8.602C13.29 1.362 10.635 0 10.635 0s.681 7.311 2.11 11.397c1.634 4.66 4.627 6.524 8.779 6.524.272 0 .545 0 .817-.071h.067c.204 0 .476-.072.68-.145h.068c.476-.144.952-.286 1.429-.5.408-.216.817-.431 1.224-.717 1.362 1.65 2.246 2.94 2.246 3.512h2.042c-.068-1.077-1.224-2.939-2.858-4.875h.001zM.192 3.542a.744.744 0 00.004.999l3.067 3.264c.716.756 1.789-.378 1.074-1.135L2.998 5.257c-.223-.236-.147-.426.164-.426h3.675c.419 0 .76-.36.76-.802 0-.445-.341-.803-.76-.803H3.162c-.314 0-.388-.191-.164-.428l1.338-1.413C5.052.627 3.978-.507 3.262.249L.192 3.542z\" fill=\"#f07048\"/>",
    "attrs": {
      "viewBox": "0 0 40 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});