define("ember-svg-jar/inlined/icn-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(.182 .099)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M4.796 1.789l2.283 4.567a1 1 0 01-.894 1.447H1.618a1 1 0 01-.894-1.447l2.283-4.567a1 1 0 011.789 0z\" stroke=\"#FFBC61\" fill=\"#FDF6ED\"/><path d=\"M3.901 2.93a.43.43 0 01.43.452L4.25 5.044a.349.349 0 01-.349.331.345.345 0 01-.344-.327l-.084-1.666A.43.43 0 013.9 2.93z\" fill=\"#FFBC61\"/><circle fill=\"#FFBC61\" cx=\"3.901\" cy=\"6.16\" r=\"1\"/></g>",
    "attrs": {
      "width": "8",
      "height": "9",
      "viewBox": "0 0 8 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});