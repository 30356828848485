define("ember-svg-jar/inlined/icn-zoom-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 1.499c0 .829-.765 1.498-1.714 1.498L1.716 3h-.001C.774 3 0 2.323 0 1.501 0 .679.774.003 1.714.003L16.284 0c.951.003 1.718.673 1.716 1.499z\" fill=\"#65615a\"/>",
    "attrs": {
      "viewBox": "0 0 18 3",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});