define("ember-svg-jar/inlined/icn-notification-acknowledge", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.982 16.136a1.132 1.132 0 001.745-.1l8.008-10.48c.411-.54.336-1.333-.17-1.773a1.134 1.134 0 00-1.661.18L7.73 13.38l-2.718-2.866a1.13 1.13 0 00-1.67.01 1.325 1.325 0 00.008 1.782l3.63 3.83\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});