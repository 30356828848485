define("ember-svg-jar/inlined/icn-sensor-leach", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.54 8.56c0-.68-.23-1.31-.59-1.81l-.05-.07c-.02-.02-.03-.05-.05-.07l-2.23-2.83-2.21 2.85c-.03.03-.07.07-.1.12l-.02.02c-.07.09-.14.17-.19.27-.26.44-.41.96-.41 1.53 0 1.66 1.31 3 2.92 3 1.62 0 2.93-1.35 2.93-3.01zm-5.41 4.47c0-.59-.2-1.12-.51-1.56l-.04-.06c-.02-.02-.03-.04-.04-.06l-1.92-2.44-1.9 2.46c-.03.03-.06.06-.08.1l-.02.02c-.06.08-.12.14-.16.23-.23.38-.35.83-.35 1.32 0 1.43 1.12 2.58 2.52 2.58 1.37.01 2.5-1.16 2.5-2.59zm-3.07-5.66c0-.43-.15-.82-.37-1.13l-.03-.04c-.01-.01-.02-.03-.03-.04l-1.39-1.77-1.38 1.78c-.02.02-.04.04-.06.07l-.01.01c-.04.05-.09.1-.12.17-.16.27-.26.6-.26.96 0 1.03.82 1.88 1.83 1.88 1.01-.01 1.82-.86 1.82-1.89zm-3.37 3.48c0-.37-.37-.49-.61-.49-.25 0-.49.25-.49.61.12.74.12 1.47 0 2.21-.12 1.35-.37 2.33-1.23 3.68-1.1 1.84-1.47 4.17-1.47 5.89v1.47c-.23-.23-.45-.54-.81-.55a.538.538 0 00-.43.17c-.09.11-.12.27-.1.4.05.25.23.34.39.5l.42.42.65.65c.12.12.25.12.37.12s.25 0 .37-.12l1.35-1.35c.18-.18.22-.49.06-.7-.13-.17-.34-.18-.55-.16-.02 0-.04 0-.06.01-.01.01-.04.01-.05.02-.2.11-.34.29-.5.45v-1.35c0-.74.12-1.47.25-2.33.25-1.47.72-2.45 1.46-3.68.84-1.46 1.22-3.3.98-5.87zm2.52 12.74c-.02 0-.04 0-.06.01-.01.01-.04.01-.05.02-.2.11-.34.29-.5.45v-1.35c0-.74.12-1.47.25-2.33.21-1.29.59-2.16 1.18-3.19-.52-.1-.68-.19-1.01-.41-1.09 1.84-1.5 4.23-1.5 5.94v1.47c-.23-.23-.45-.54-.81-.55a.538.538 0 00-.43.17c-.09.11-.12.27-.1.4.05.25.23.34.39.5l.42.42.64.64c.12.12.25.12.37.12s.25 0 .37-.12l1.35-1.35c.18-.18.22-.49.06-.7-.15-.15-.36-.16-.57-.14zm4.25-3.19c.25-1.47.72-2.45 1.46-3.68.62-1.04.98-2.08 1.06-3.63-.04 0-.09.01-.13.01-.6-.01-.65-.01-1-.13-.01.12-.01.09-.03.21-.12 1.35-.37 2.33-1.23 3.68-1.1 1.84-1.47 4.17-1.47 5.89v1.47c-.23-.23-.45-.54-.81-.55a.538.538 0 00-.43.17c-.09.11-.12.27-.1.4.05.25.23.34.39.5l.42.42.64.64c.12.12.25.12.37.12s.25 0 .37-.12l1.35-1.35c.18-.18.22-.49.06-.7-.13-.17-.34-.18-.55-.16-.02 0-.04 0-.06.01-.01.01-.04.01-.05.02-.2.11-.34.29-.5.45v-1.35c-.01-.72.12-1.46.24-2.32z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});