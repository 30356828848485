define("ember-svg-jar/inlined/icn-communication-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.95.27C11.31.27.25 11.33.25 24.97s11.06 24.7 24.7 24.7 24.7-11.06 24.7-24.7S38.59.27 24.95.27zm2.65 9.98l-.58 18.25h-4.11l-.61-18.25h5.3zm-.35 28.47c-.63.64-1.39.96-2.3.96-.91 0-1.67-.32-2.3-.96-.63-.64-.94-1.4-.94-2.28 0-.91.31-1.67.94-2.3.63-.63 1.39-.94 2.3-.94.91 0 1.67.31 2.3.94s.94 1.39.94 2.3c0 .88-.32 1.64-.94 2.28z\" fill=\"#bab5ad\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});