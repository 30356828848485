define("ember-svg-jar/inlined/icn-notification-tension", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.996 29.976c-4.429 0-7.996-3.433-7.996-7.692 0-1.436.407-2.78 1.128-3.91.137-.26.317-.477.497-.698l.047-.044c.09-.132.18-.217.27-.305l6.054-7.303 6.1 7.259c.048.044.09.132.138.172l.137.173C27.366 18.93 28 20.539 28 22.28c-.004 4.219-3.576 7.696-8.004 7.696z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});