define("ember-svg-jar/inlined/icn-create-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5 9.436a.486.486 0 01-.482.477h-.703a.486.486 0 01-.482-.477v-.697a.482.482 0 01.48-.478h.707c.263 0 .479.215.48.478m3.333 0a.485.485 0 00-.482-.478h-.703a.486.486 0 00-.481.478v.697a.482.482 0 00.481.477h.703a.485.485 0 00.473-.477m2.158.477h.703a.486.486 0 00.482-.476v-.698a.485.485 0 00-.482-.478h-.703a.485.485 0 00-.482.478v.698c.004.26.22.47.482.47M4.52 11.566h-.706a.482.482 0 00-.48.476v.699c0 .261.217.477.48.477h.706a.482.482 0 00.48-.476v-.699a.48.48 0 00-.478-.477h-.003m3.34 0h-.71a.485.485 0 00-.483.477v.698a.483.483 0 00.48.477h.706a.485.485 0 00.481-.477v-.698a.484.484 0 00-.474-.477m-6.036 4.957h7.908a5.673 5.673 0 01-.34-1.935H2.005V6.533h14.313v2.171c.7.081 1.381.28 2.015.585V3.762c-.001-.966-.817-1.75-1.823-1.75h-1.936V.693a.713.713 0 00-.71-.693h-1.142a.739.739 0 00-.51.203.676.676 0 00-.212.491v1.318H6.33V.693a.713.713 0 00-.717-.69H4.481a.715.715 0 00-.721.691v1.318H1.824C.817 2.01 0 2.794 0 3.762V14.77c0 .967.817 1.75 1.824 1.75M20 14.458C20 16.949 17.931 19 15.417 19c-2.514 0-4.583-2.051-4.583-4.543 0-2.493 2.069-4.544 4.583-4.544 2.514 0 4.583 2.051 4.583 4.544v.001zm-2.96-.455h-1.164v-1.154a.46.46 0 00-.459-.459.46.46 0 00-.459.459v1.155h-1.166a.461.461 0 00-.459.455c0 .25.207.455.459.455h1.166v1.156a.46.46 0 00.459.459.46.46 0 00.459-.459v-1.156h1.165c.25 0 .457-.205.459-.455a.461.461 0 00-.459-.455\" fill=\"#65615a\"/>",
    "attrs": {
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});