define("ember-svg-jar/inlined/icn-schedule-single-irrigation-inferred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"a\" d=\"M363.497 233C351.039 233 341 223.02 341 210.636c0-4.17 1.142-8.084 3.178-11.372.382-.76.888-1.391 1.4-2.023l.129-.129c.253-.38.506-.631.765-.883L363.498 175l17.16 21.1c.129.128.253.38.382.503l.382.504c2.795 3.79 4.578 8.465 4.578 13.519-.005 12.265-10.044 22.373-22.502 22.373l-.001.001z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(-331 -165)\"><use fill=\"#B9E6F7\" xlink:href=\"#a\"/><path stroke=\"#19ACE4\" d=\"M363.367 232.5h.131c12.138 0 21.997-9.842 22.002-21.874 0-4.732-1.605-9.324-4.476-13.217l-.33-.444a1.77 1.77 0 01-.26-.333l-.004-.005a1.427 1.427 0 00-.16-.211l-16.77-20.62-16.68 20.79c-.344.337-.507.52-.697.804l-.063.077-.093.088c-.724.894-1.043 1.338-1.364 1.973-2.02 3.261-3.103 7.094-3.103 11.108 0 12.064 9.745 21.795 21.867 21.864z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "65",
      "height": "76",
      "viewBox": "0 0 65 76"
    }
  };
});