define("ember-svg-jar/inlined/icn-live-weather-humidity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.6 14c0-.1 2.4-2.9 2.4-2.9l2.5 3c.4.5.6 1.1.6 1.8-.1 1.5-1.4 2.9-3.1 2.9-1.7 0-3-1.3-3-3 0-.6.2-1.1.4-1.5l.2-.3zm-3.21-3.79c0-.07 1.59-1.92 1.59-1.92l1.66 1.99c.27.33.4.73.4 1.19-.07.99-.93 1.92-2.06 1.92s-1.99-.86-1.99-1.99c0-.4.13-.73.27-.99l.13-.2zM12.13 21.4c.36 0 .78-.09 1.11-.21.39-.14.76-.39 1.06-.69.8.8 1.2 1.5 1.3 1.8h1.1c-.1-.7-.7-1.6-1.6-2.6-1.2-1.3-2.9-2.8-4.1-3.8-.1-.1-.1-.1-.1-.2 0-.2.1-.4.4-.4.1 0 .2 0 .3.1 1.7 1.4 3 2.7 4 3.7.1-.1.15-.22.2-.4 0 0 .23-.74.16-1.84-.02-.41-.11-.85-.31-1.25-.5-1-1.19-1.59-2.19-2.09S11.1 12.9 10 13c-2.6.1-4-.6-4-.6s.5 3.7 1.3 5.8c1 2.3 2.6 3.2 4.8 3.2\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});