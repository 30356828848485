define("ember-svg-jar/inlined/icn-schedule-single-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\"><path d=\"M32.497 68C20.04 68 10 58.02 10 45.636c0-4.17 1.142-8.084 3.178-11.372.382-.76.888-1.39 1.4-2.023l.13-.13c.253-.38.506-.63.765-.883L32.498 10l17.16 21.1c.13.128.253.38.382.503l.382.504c2.795 3.8 4.578 8.465 4.578 13.52C54.995 57.89 44.956 68 32.498 68h-.001z\" fill=\"#19ACE4\"/><path d=\"M32.334 33.455V48.28m8.052 7.98l-8.052-7.667\" stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"5\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 65 76"
    }
  };
});