define("ember-svg-jar/inlined/icn-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.594 2.291L18 0v21l-9.406-2.291v-4.993h1.894v3.555l5.649 1.379V2.35l-5.649 1.38v3.555H8.594V2.291zM4.597 5.962L5.92 7.255l-2.345 2.29h10.247v1.851H3.606l2.314 2.261-1.323 1.293L0 10.455l4.597-4.493z\" fill=\"#65615a\"/>",
    "attrs": {
      "viewBox": "0 0 18 21",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});