define("ember-svg-jar/inlined/icn-automated-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.516 11.527l-2.691-1.14a8.662 8.662 0 00-.834-2.028l1.09-2.658a.962.962 0 00-.209-1.044l-.9-.9a.962.962 0 00-1.045-.21l-2.658 1.09a8.651 8.651 0 00-2.028-.833l-1.114-2.66a.96.96 0 00-.886-.59h-1.28a.961.961 0 00-.885.586l-1.14 2.692a8.662 8.662 0 00-2.028.833L5.25 3.575a.962.962 0 00-1.045.21l-.9.9a.962.962 0 00-.21 1.044l1.09 2.658a8.651 8.651 0 00-.832 2.028l-2.66 1.114a.96.96 0 00-.59.887v1.279c0 .386.23.734.586.885l2.691 1.14c.195.722.473 1.39.834 2.028l-1.09 2.658a.962.962 0 00.209 1.045l.9.9a.962.962 0 001.045.21l2.658-1.09a8.651 8.651 0 002.028.833l1.114 2.66a.96.96 0 00.886.59h1.28a.961.961 0 00.885-.587l1.14-2.691a8.662 8.662 0 002.028-.834l2.658 1.09A.962.962 0 0021 22.324l.9-.9a.962.962 0 00.21-1.045l-1.09-2.658a8.651 8.651 0 00.833-2.028l2.66-1.114a.96.96 0 00.59-.886v-1.28a.961.961 0 00-.587-.885zm-11.913 7.34a5.812 5.812 0 110-11.626 5.812 5.812 0 010 11.625z\" fill=\"#2449E3\"/>",
    "attrs": {
      "viewBox": "0 0 26 26",
      "width": "26",
      "height": "26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});