define("ember-svg-jar/inlined/icn-notification-none", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.942 5.781c0 .266-.104.48-.338.612l-3.99 3.026 4.042 2.922c.21.155.335.402.338.664v3.4h2.02V18H.986v-1.594H3.11v-3.4a.8.8 0 01.31-.637l4.018-3.001-4.043-2.923a.836.836 0 01-.337-.664V2.593H.985V1h15.03v1.594h-2.073V5.78zm-1.503 10.625V13.43l-3.99-2.895-3.784 2.87v3.001h7.774zM8.603 8.252l3.784-2.869V2.594H4.613v2.762l3.99 2.896z\"/>",
    "attrs": {
      "viewBox": "0 0 17 19",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});