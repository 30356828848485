define("ember-svg-jar/inlined/icn-menu-weather", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.91 22.55c.3-.46.6-.87.96-1.29-1.36-3.3-4.59-5.62-8.27-5.62-4.94 0-8.97 4.12-8.97 9.17 0 3.3 1.71 6.18 4.29 7.83.91-2.48 3.02-4.33 5.6-4.79.89-2.86 3.4-4.93 6.39-5.3zM8.33 16.99c.26.26.52.36.83.36.31 0 .62-.1.83-.36.47-.47.47-1.22 0-1.69l-2.76-2.71c-.48-.46-1.24-.46-1.72 0a1.19 1.19 0 000 1.69l2.82 2.71zM8.31 33l-2.78 2.71c-.47.46-.47 1.21-.02 1.67 0 .01.01.01.02.02.26.26.52.36.84.36.32.01.63-.12.84-.36l2.78-2.71c.47-.46.47-1.21.01-1.67 0-.01 0-.02-.01-.02-.46-.46-1.22-.46-1.68 0zM27.1 17.35c.31 0 .62-.1.83-.36l2.76-2.71c.47-.47.47-1.22 0-1.69-.48-.46-1.23-.46-1.71 0l-2.76 2.71a1.19 1.19 0 000 1.69c.25.26.56.36.88.36zm16.89 10.37c-1.03-4.03-4.47-6.97-8.63-6.97-3.6 0-6.68 2.23-8.07 5.45-.16 0-.36-.06-.51-.06-2.93 0-5.34 2.45-5.45 5.55-.16 0-.36-.05-.51-.05-2.93 0-5.29 2.5-5.29 5.61 0 3.11 2.36 5.61 5.29 5.61h21.99c3.96 0 7.19-3.43 7.19-7.62-.05-3.82-2.62-6.92-6.01-7.52zM6 25H1c-.55 0-1-.45-1-1s.45-1 1-1h5c.55 0 1 .45 1 1s-.45 1-1 1zm12-11c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1s1 .45 1 1v5c0 .55-.45 1-1 1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});