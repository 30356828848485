define("ember-svg-jar/inlined/icn-zoom-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.715 10.739h5.571c.94 0 1.714-.779 1.714-1.725 0-.946-.774-1.725-1.714-1.725h-5.571V1.683A1.727 1.727 0 009.001 0c-.925 0-1.692.754-1.714 1.684V7.29H1.715C.774 7.29 0 8.069 0 9.016c0 .946.774 1.725 1.715 1.725l5.571-.001v5.606C7.324 17.265 8.086 18 9 18c.914 0 1.676-.736 1.714-1.655v-5.606h.001z\" fill=\"#65615a\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});