define("ember-svg-jar/inlined/icn-menu-nutrients", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10.005 0a2.501 2.501 0 012.479 2.503c0 1.432-1.095 2.122-1.51 2.318l-.01 3.605c1.74.37 3.047 1.935 3.047 3.811 0 .063-.001.126-.004.188l3.448 1.172c.03-.027.666-1.14 2.08-1.14 1.474 0 2.465 1.252 2.465 2.503 0 1.251-1.016 2.503-2.466 2.503-1.822 0-2.499-1.716-2.465-2.292l-3.546-1.032a3.825 3.825 0 01-3.347 1.991 3.784 3.784 0 01-2.417-.87l-2.965 2.43s.137.357.137.807C4.931 19.803 3.923 21 2.466 21 1.008 21 0 19.787 0 18.497s1.034-2.503 2.466-2.503c.647 0 1.21.295 1.21.325l3.008-2.47a3.929 3.929 0 01-.343-1.612c0-1.81 1.217-3.333 2.866-3.768l.002-3.6c-.482-.107-1.656-.934-1.656-2.366S8.703 0 10.005 0zm-7.54 17.663a.828.828 0 00-.821.834c0 .46.368.834.822.834a.828.828 0 00.821-.834.828.828 0 00-.821-.834zm17.07-3.537a.828.828 0 00-.822.834c0 .46.368.834.821.834a.828.828 0 00.822-.834.828.828 0 00-.822-.834zm-9.36-4.114c-1.21 0-2.19.996-2.19 2.225s.98 2.225 2.19 2.225c1.211 0 2.193-.996 2.193-2.225s-.982-2.225-2.192-2.225zm-.157-8.343a.828.828 0 00-.822.834c0 .46.368.834.822.834a.828.828 0 00.822-.834.828.828 0 00-.822-.834z\"/>",
    "attrs": {
      "width": "22",
      "height": "21",
      "viewBox": "0 0 22 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});