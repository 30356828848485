define("ember-svg-jar/inlined/icn-tension-low", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#6ed191\" fill-rule=\"nonzero\"><path d=\"M18.632 18.207c-3.438-1.434-6.126-2.201-8.722-2.331v-4.342h1.252c3.725 0 6.752-2.988 6.752-6.663v-.598h-3.253a5.866 5.866 0 00-4.926 2.669c-.041-.04-.092-.06-.133-.089V5.767C9.581 2.58 6.954 0 3.722 0H.469v.598c0 3.675 4.484 6.872 8.209 6.872v8.397c-2.545.079-5.1.766-8.301 2.091a.599.599 0 00-.329.787.63.63 0 00.811.319c3.048-1.255 5.449-1.923 7.819-1.992v7.33c0 .329.277.598.616.598a.609.609 0 00.616-.598v-7.33c2.432.129 4.956.866 8.229 2.231a.73.73 0 00.247.05c.236 0 .462-.14.564-.359a.593.593 0 00-.318-.787z\"/><path d=\"M4.861 20.637c-.39-.378-.965.19-.585.568l.718.707c.123.12.082.219-.092.219h-1.97a.407.407 0 00-.411.399c0 .219.185.398.411.398h1.991c.164 0 .205.1.092.219l-.718.708c-.39.378.195.946.584.567l1.663-1.653a.35.35 0 000-.498l-1.683-1.634zm10.59 1.494H13.46c-.164 0-.205-.099-.092-.219l.718-.707c.39-.378-.195-.946-.585-.568l-1.662 1.654a.35.35 0 000 .498l1.652 1.643c.39.379.965-.189.585-.567l-.718-.708c-.124-.119-.083-.209.092-.209h1.991c.225 0 .41-.179.41-.398a.396.396 0 00-.4-.419z\"/></g>",
    "attrs": {
      "viewBox": "0 0 19 25",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});