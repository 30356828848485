define("ember-svg-jar/inlined/icn-out-of-field-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#FFF\"><path d=\"M2.889 5.994V4.397c0-.76.649-1.369 1.43-1.369h3.444V.976c0-.305-.265-.558-.583-.558H.65a.575.575 0 00-.581.558v8.439c0 .304.265.557.583.557h6.556c.318 0 .583-.253.583-.557V7.362H4.346c-.808 0-1.457-.62-1.457-1.368z\"/><path d=\"M12.492 4.878L9.459 2.534c-.279-.215-.69-.025-.69.317v1.153H4.32a.41.41 0 00-.423.406v1.596a.41.41 0 00.423.406h4.45v1.153c0 .342.411.52.69.317l3.033-2.357c.212-.178.212-.482 0-.647z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "10",
      "viewBox": "0 0 13 10"
    }
  };
});