define("ember-svg-jar/inlined/icn-sun", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.023 5.356a.935.935 0 001.318.005.91.91 0 00-.004-1.304l-1.3-1.28a.935.935 0 00-1.318-.01.906.906 0 00.01 1.304l1.294 1.285zM17.813 8.5h-1.565c-.445 0-.801.418-.801.936 0 .522.348.94.8.94h1.566c.444 0 .8-.418.8-.94 0-.518-.352-.936-.8-.936M3.614 10.28a.786.786 0 00-.8-.78H1.247c-.445 0-.8.349-.8.78 0 .437.351.785.8.785h1.567c.444 0 .8-.348.8-.784M9.767 5.5c-2.387 0-4.32 1.912-4.32 4.272 0 2.356 1.934 4.271 4.32 4.271 2.387 0 4.322-1.915 4.322-4.271 0-2.36-1.935-4.272-4.322-4.272m-5.745 8.276L2.727 15.06a.91.91 0 00-.01 1.304.938.938 0 001.32-.01l1.3-1.279a.912.912 0 00.004-1.304.932.932 0 00-1.319.005m11.017-8.42l1.296-1.285a.913.913 0 00.01-1.304.936.936 0 00-1.32.01l-1.3 1.28a.91.91 0 00-.005 1.304.937.937 0 001.32-.005m-.002 8.418a.935.935 0 00-1.318-.004.912.912 0 00.004 1.305l1.3 1.28a.94.94 0 001.32.01.913.913 0 00-.01-1.305l-1.296-1.286zM10.237 3.63c.44 0 .793-.347.793-.79V1.292A.79.79 0 0010.237.5a.786.786 0 00-.79.792V2.84c0 .44.352.79.79.79m.156 11.87c-.524 0-.946.347-.946.791v1.548c0 .44.422.791.946.791.529 0 .952-.348.952-.791v-1.548c0-.44-.423-.791-.952-.791\" fill=\"#F4AE4E\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 19"
    }
  };
});