define("ember-svg-jar/inlined/icn-sensor-cellular-success-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M27.21 6.13c0-1.13-.92-2.05-2.05-2.05h-.03c-1.13 0-2.05.92-2.05 2.05v18.49c0 1.13.92 2.05 2.05 2.05h.03c1.13 0 2.05-.92 2.05-2.05V6.13zm-6.78 4.2c0-1.13-.92-2.05-2.05-2.05h-.03c-1.13 0-2.05.92-2.05 2.05v14.3c0 1.13.92 2.05 2.05 2.05h.03c1.13 0 2.05-.92 2.05-2.05v-14.3zm-6.78 4.72c0-1.13-.92-2.05-2.05-2.05h-.03c-1.13 0-2.05.92-2.05 2.05v9.58c0 1.13.92 2.05 2.05 2.05h.03c1.13 0 2.05-.92 2.05-2.05v-9.58zm-6.79 9.57c0 1.13-.92 2.05-2.05 2.05h-.02c-1.13 0-2.05-.92-2.05-2.05v-4.73c0-1.13.92-2.05 2.05-2.05h.03c1.13 0 2.05.92 2.05 2.05l-.01 4.73z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});