define("ember-svg-jar/inlined/icn-autom-water-level-empty-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"a\" d=\"M12 0h4.614v21.6H12V0z\"/></defs><g transform=\"translate(1.114 4.741)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M0 17.92c0-.686.207-1.33.575-1.871.07-.125.161-.23.254-.333l.023-.021a.883.883 0 01.139-.146l3.083-3.493 3.107 3.472c.023.021.046.062.07.083l.068.083c.507.623.83 1.393.83 2.224-.001 2.019-1.82 3.682-4.075 3.682C1.818 21.6 0 19.958 0 17.92zM6.932 7.062l-.828-.828-1.468 1.468v-6.42H3.45v6.4L2 6.234l-.828.828 2.88 2.88z\" fill=\"#BBB8B3\"/><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M16.584 10.582V.842a.724.724 0 00.03-.207c0-.35-.24-.635-.536-.635h-3.542C12.241 0 12 .285 12 .635c0 .35.24.634.536.634h2.976v2.116H13.9c-.285 0-.516.273-.516.61 0 .385.231.659.516.659h1.612v2.115H13.9c-.285 0-.516.274-.516.61 0 .386.231.66.516.66h1.612v2.115h-2.976c-.295 0-.536.285-.536.635 0 .35.24.634.536.634h2.976v2.116H13.9c-.285 0-.516.274-.516.61 0 .385.231.66.516.66h1.612v2.115H13.9c-.285 0-.516.274-.516.61 0 .385.231.659.516.659h1.612v2.115h-2.976c-.295 0-.536.285-.536.635 0 .35.24.635.536.635l3.385-.003c.031.01.075.025.127.025a.426.426 0 00.234-.072c.202-.099.332-.328.332-.585 0-.07-.01-.14-.03-.207v-9.74a.722.722 0 000-.414z\" fill=\"#BBB8B3\" mask=\"url(#b)\"/></g>",
    "attrs": {
      "viewBox": "0 0 18 31",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});