define("ember-svg-jar/inlined/icn-sensor-vpd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style/><path id=\"VPD\" d=\"M8.8 11.4h1.8L7.7 20h-2l-2.9-8.6h1.8l1.6 5.1c.1.3.2.7.3 1.1s.2.7.2.8c0-.4.2-1 .4-1.9l1.7-5.1zm5.1 4.1h.6c.6 0 1-.1 1.3-.3s.4-.5.4-1c0-.4-.1-.7-.3-.9s-.6-.3-1.1-.3H14v2.5zm4.1-1.4c0 .9-.3 1.6-.9 2.1s-1.4.8-2.4.8h-.8v3h-1.8v-8.6h2.7c1 0 1.8.2 2.4.7s.8 1.1.8 2zm9.1 1.5c0 1.4-.4 2.5-1.2 3.2s-2 1.1-3.5 1.1H20v-8.6h2.7c1.4 0 2.5.4 3.3 1.1s1.1 1.9 1.1 3.2zm-1.9.1c0-1.8-.8-2.8-2.4-2.8h-1v5.6h.8c1.7 0 2.6-.9 2.6-2.8z\"/>",
    "attrs": {
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});