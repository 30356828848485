define("ember-svg-jar/inlined/icn-wind-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#fff}</style><path class=\"st0\" d=\"M12.5 22.87c-5.72 0-10.37-4.65-10.37-10.37S6.78 2.13 12.5 2.13 22.87 6.78 22.87 12.5 18.22 22.87 12.5 22.87zM12.5 0C5.6 0 0 5.6 0 12.5S5.6 25 12.5 25 25 19.4 25 12.5 19.4 0 12.5 0z\"/><path class=\"st0\" d=\"M12.5 14.01c-.58 0-1.05-.43-1.05-.97s.47-.97 1.05-.97c.58 0 1.05.43 1.05.97s-.47.97-1.05.97zm4.75-8.54a.321.321 0 00-.35.04l-6.88 6.13c-.04.03-.07.08-.08.13l-2.33 8.56c-.03.11.02.23.13.29.04.02.1.04.14.04.07 0 .15-.03.2-.07l6.88-6.13c.04-.03.07-.08.08-.13l2.32-8.56c.05-.12 0-.24-.11-.3z\"/><path d=\"M17.76 4.46a.364.364 0 00-.41.05l-7.79 6.9c-.05.04-.08.09-.09.15l1.91 1.02c.2-.38.62-.66 1.11-.65.69 0 1.25.51 1.25 1.15 0 .24-.1.45-.24.64l1.93 1.02c.05-.04.08-.09.09-.15l2.39-9.77a.315.315 0 00-.15-.36z\" fill=\"#f07048\"/>",
    "attrs": {
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
});