define("ember-svg-jar/inlined/icn-drop-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.688 38.88C7.317 38.88.57 32.19.57 23.889c0-2.796.768-5.42 2.136-7.623.257-.51.597-.934.94-1.357l.087-.086c.17-.255.34-.424.514-.592L15.69 0l11.53 14.144c.087.086.17.255.257.338l.257.337c1.878 2.541 3.076 5.675 3.076 9.062-.003 8.222-6.75 14.999-15.121 14.999h-.001z\" fill=\"#dcdbd9\"/>",
    "attrs": {
      "viewBox": "0 0 31 39",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});