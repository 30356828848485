define("ember-svg-jar/inlined/icn-csv-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#C6D3DA\" fill-rule=\"evenodd\" d=\"M44.807 18.34v32.587A4.073 4.073 0 0140.732 55H4.072h.003A4.073 4.073 0 010 50.927V4.084A4.073 4.073 0 014.075.01h26.354a2.115 2.115 0 011.69.589l12.038 12.04h-.003c.426.43.643 1.024.591 1.628h.062v4.072H28.513a2.036 2.036 0 01-2.037-2.036V4.084H4.073v46.841h36.66V18.34h4.074z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 45 55"
    }
  };
});