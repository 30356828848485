define("ember-svg-jar/inlined/icn-sensor-water-depth", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icnWaterDepth</title><path d=\"M5 23.226c0-.79.238-1.532.663-2.155.08-.144.185-.264.292-.383l.027-.025c.052-.072.105-.12.16-.167l3.55-4.023 3.578 3.998c.027.025.053.073.08.096l.08.095c.583.719.954 1.605.954 2.562 0 2.325-2.094 4.24-4.692 4.24-2.598 0-4.692-1.89-4.692-4.238zM24.1 14.776V3.557a.834.834 0 00.034-.238c0-.403-.277-.73-.617-.73h-4.08c-.34 0-.616.327-.616.73 0 .403.276.731.617.731h3.427v2.436h-1.857c-.327 0-.594.316-.594.703 0 .444.267.76.594.76h1.857v2.435h-1.857c-.327 0-.594.316-.594.704 0 .443.267.758.594.758h1.857v2.437h-3.427c-.34 0-.617.328-.617.73 0 .404.276.731.617.731h3.427v2.437h-1.857c-.327 0-.594.315-.594.703 0 .443.267.758.594.758h1.857v2.437h-1.857c-.327 0-.594.316-.594.703 0 .443.267.759.594.759h1.857v2.436h-3.427c-.34 0-.617.328-.617.73 0 .404.276.732.617.732l3.897-.004c.037.012.087.03.147.03a.49.49 0 00.27-.083c.232-.114.382-.378.382-.674a.84.84 0 00-.035-.238V15.252a.832.832 0 000-.476z\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});