define("ember-svg-jar/inlined/icn-cross", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 10.824l4.59 4.59a2 2 0 001.441.614 2.008 2.008 0 001.998-1.998c0-.544-.222-1.065-.615-1.442l-4.59-4.59 4.59-4.59a2 2 0 00.546-1.372A2.008 2.008 0 0013.962.038a2 2 0 00-1.374.547L8 5.175 3.41.586A1.998 1.998 0 001.997 0 2.008 2.008 0 00-.002 1.999c0 .529.211 1.037.585 1.412L5.174 8l-4.59 4.59a1.997 1.997 0 00-.546 1.373c0 1.096.902 1.998 1.998 1.998a2 2 0 001.373-.547L8 10.824z\" fill=\"#dddbd8\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});