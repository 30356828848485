define("ember-svg-jar/inlined/icn-trend-increasing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter x=\"-22.1%\" y=\"-22.1%\" width=\"144.3%\" height=\"144.3%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feMorphology radius=\"1.5\" operator=\"dilate\" in=\"SourceAlpha\" result=\"shadowSpreadOuter1\"/><feOffset in=\"shadowSpreadOuter1\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"2\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feComposite in=\"shadowBlurOuter1\" in2=\"SourceAlpha\" operator=\"out\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\" in=\"shadowBlurOuter1\"/></filter><circle id=\"b\" cx=\"17.108\" cy=\"17.234\" r=\"16.941\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"scale(1 -1) rotate(17 152.207 15.611)\"><use fill=\"#000\" filter=\"url(#a)\" xlink:href=\"#b\"/><use stroke=\"#FFF\" stroke-width=\"3\" fill=\"#98948E\" xlink:href=\"#b\"/></g><path d=\"M29.849 13.652a1.395 1.395 0 00-1.736-.914l-8.653 2.627c-2.01.619-1.093 3.639.917 3.02l3.759-1.156c.626-.191.883.122.579.694l-3.599 6.768-7.952-4.228-5.771 10.854 3.136.845 3.948-7.425 7.953 4.228 5.067-9.562c.306-.576.709-.538.9.092l1.142 3.762c.613 2.013 3.63 1.085 3.017-.928l-2.707-8.677z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});