define("ember-svg-jar/inlined/icn-sensor-wind-gust", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.843 20.163c.874.876 2.28.876 3.125 0l4.099-4.1 11.972-5.442c.431-.196.25-.844-.22-.789a2.482 2.482 0 01-2.054-.71 2.484 2.484 0 01-.68-2.252c.055-.19.019-.348-.11-.476-.128-.128-.287-.165-.476-.11a2.484 2.484 0 01-2.251-.68 2.482 2.482 0 01-.711-2.054c.056-.47-.593-.651-.79-.22L8.316 15.28l-4.127 4.128c-.431.336-1.063.36-1.474.072l-.09-.072c-.404-.363-.378-1.149 0-1.59a.571.571 0 000-.755c-.23-.272-.563-.204-.78 0-.874.818-.874 2.223 0 3.1zm7.971-5.489l4.178-9.19a3.036 3.036 0 003.04 1.973 3.036 3.036 0 001.973 3.04l-9.191 4.177z\"/>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});