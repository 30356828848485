define("ember-svg-jar/inlined/icn-sensor-humidity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18 12.5c-1.38 0-2.5-1.2-2.5-2.7 0-.5.13-.98.35-1.37.04-.09.1-.17.16-.24l.01-.02c.03-.05.06-.08.08-.11l1.89-2.56 1.91 2.55c.01.02.03.05.04.06l.04.06c.31.46.51 1.02.51 1.63.01 1.48-1.11 2.7-2.49 2.7zm4 7c-1.94 0-3.5-1.55-3.5-3.47 0-.65.18-1.25.49-1.76.06-.12.14-.22.22-.31l.02-.02c.04-.06.08-.1.12-.14L22 10.51l2.67 3.27c.02.02.04.06.06.08l.06.08c.43.59.71 1.31.71 2.1 0 1.89-1.56 3.46-3.5 3.46zM15.12 20.55c-1.41-1.57-3.37-3.29-4.76-4.48a.403.403 0 01-.14-.29c-.01-.26.15-.43.41-.44.08 0 .21.03.3.08h.04c1.96 1.68 3.53 3.12 4.68 4.36.08-.17.16-.35.24-.52 0 0 0-.04.04-.09.04-.13.07-.3.11-.43 0-.04.04-.09.04-.13.14-.47.06-.96 0-1.44s-.16-.95-.37-1.39c-.54-1.12-1.55-1.92-2.66-2.42-1.2-.54-2.55-.77-3.86-.74-3 .06-4.67-.69-4.67-.69s.57 4.33 1.55 6.73c1.11 2.73 3.01 3.78 5.58 3.69.17-.01.34-.01.51-.06h.04c.13 0 .29-.05.42-.1h.04c.29-.1.59-.19.88-.33.25-.14.5-.27.75-.45.88.95 1.45 1.7 1.47 2.04l1.27-.04c-.1-.66-.86-1.74-1.91-2.86z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});