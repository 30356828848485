define("ember-svg-jar/inlined/icn-angle-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8 7c0 .324-.114.634-.357.885l-5.574 5.75a1.19 1.19 0 01-1.715 0 1.29 1.29 0 010-1.77L5.07 7 .354 2.134a1.288 1.288 0 010-1.769 1.19 1.19 0 011.715 0l5.574 5.75c.243.25.357.56.357.885z\" fill=\"#bbb8b3\"/>",
    "attrs": {
      "viewBox": "0 0 8 14",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});