define("ember-svg-jar/inlined/icn-flash-bang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20 1A18.896 18.896 0 006.56 6.564c-7.412 7.408-7.415 19.462 0 26.872A18.891 18.891 0 0019.994 39a18.9 18.9 0 0013.444-5.565c7.415-7.41 7.415-19.464 0-26.87A18.896 18.896 0 0020 1z\" fill=\"#fef5f3\"/><path d=\"M20.177.001c11.165.18 21.075 10.908 19.694 22.393-1.147 9.532-9.773 17.594-19.944 17.607C6.83 40.015-4.536 24.56 1.812 11.353 5.022 4.672 12.152.12 19.684.001c.247-.002.247-.002.493 0zm-.474 2C9.66 2.16.741 11.813 1.983 22.157 3.29 33.027 15.98 41.28 27.056 36.554c8.912-3.803 13.567-15.506 9.087-24.536-2.934-5.91-9.274-9.908-15.984-10.017h-.455z\" fill=\"#f07048\" fill-rule=\"nonzero\"/><path d=\"M18.031 22.631l-.595-13.593h5.126l-.556 13.593h-3.975zm4.193 4.208c.606.467.908 1.037.908 1.712 0 .654-.302 1.221-.908 1.697-.606.476-1.347.714-2.224.714-.877 0-1.618-.238-2.224-.714-.606-.476-.908-1.043-.908-1.697 0-.674.302-1.245.908-1.712.606-.467 1.347-.7 2.224-.7.877 0 1.618.233 2.224.7z\" fill=\"#f07048\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});