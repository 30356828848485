define("ember-svg-jar/inlined/icn-sensor-solar-radiation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.761 14.153l2.642 2.642 1.065 1.065c.025-.367-.013-.777.276-1.084a.672.672 0 01.487-.236.496.496 0 01.379.214c.138.22.056.447.038.697a457.772 457.772 0 00-.117 1.679c-.012.193-.134.314-.237.418-.104.103-.188.187-.382.2l-2.12.148c-.29.02-.546-.174-.565-.46-.012-.24.155-.424.348-.58a.147.147 0 01.06-.043c.02-.002.041-.023.06-.025.245-.083.503-.072.755-.09l-.975-.974-2.53-2.53-.914-.915c-.183-.059-.16-.572.131-.863.291-.29.674-.188.797-.065l.802.802zm-6.131 1l2.642 2.642 1.065 1.065c.024-.367-.014-.777.276-1.084a.672.672 0 01.486-.236.496.496 0 01.38.214c.137.22.055.447.038.697l-.046.656-.071 1.023c-.013.193-.135.314-.238.418-.103.103-.188.187-.381.2l-2.121.148c-.29.02-.545-.174-.565-.46-.011-.24.156-.424.348-.58a.136.136 0 01.06-.043c.02-.002.042-.023.06-.025.246-.083.504-.072.756-.09l-.975-.974-2.53-2.53-.914-.915c-.183-.059-.16-.572.13-.863.292-.29.675-.188.798-.065l.802.802zm7.123-7l2.642 2.642 1.065 1.065c.024-.367-.014-.777.276-1.084a.672.672 0 01.487-.236.496.496 0 01.378.214c.138.22.056.447.039.697a457.772 457.772 0 00-.117 1.679c-.013.193-.134.314-.238.418-.103.103-.188.187-.38.2l-2.122.148c-.29.02-.545-.174-.565-.46-.011-.24.156-.424.348-.58a.147.147 0 01.06-.043c.02-.002.042-.023.06-.025.246-.083.504-.072.756-.09l-.975-.974-2.53-2.53-.914-.915c-.183-.059-.16-.572.13-.863.292-.29.675-.188.798-.065l.802.802zM10.258 2.37c0-.61.496-1.101 1.128-1.101h2.208c.628 0 1.13.492 1.13 1.1a1.11 1.11 0 01-1.13 1.107h-2.208a1.112 1.112 0 01-1.128-1.106M7.707 8.15c-.43.43-.428 1.128.02 1.575l1.56 1.561a1.11 1.11 0 001.577.02 1.11 1.11 0 00-.016-1.58L9.287 8.164a1.112 1.112 0 00-1.58-.015m-5.517 6.61c-.608 0-1.1-.496-1.1-1.128v-2.208c0-.628.492-1.13 1.1-1.13a1.11 1.11 0 011.107 1.13v2.208c0 .627-.491 1.128-1.106 1.128M8.043.678c.159.538.244 1.106.244 1.694 0 3.323-2.727 6.024-6.09 6.024-.658 0-1.29-.103-1.883-.293v-5.73c0-1.23.45-1.695 1.816-1.695\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 22 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});