define("ember-svg-jar/inlined/icn-sensor-dielectric-permittivity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.56 13.62v1.65h-1.31c-.76 0-1.33.08-1.71.25s-.57.44-.57.82c0 .65.7.98 2.09.98.54 0 1.09-.06 1.66-.18.57-.12 1.09-.28 1.55-.5v1.91c-.9.4-2.03.6-3.39.6-1.39 0-2.44-.21-3.18-.64-.73-.43-1.1-1.07-1.1-1.93 0-.56.17-1.01.5-1.36.33-.35.88-.6 1.62-.74v-.08c-1.11-.25-1.66-.93-1.66-2.02 0-.73.33-1.3.99-1.7.66-.4 1.6-.59 2.82-.59.6 0 1.2.06 1.81.18.61.12 1.14.28 1.6.48l-.73 1.73c-.62-.25-1.11-.42-1.47-.5s-.74-.12-1.14-.12c-.59 0-1.03.07-1.31.2-.29.13-.43.34-.43.62 0 .34.17.59.52.73s.89.21 1.64.21h1.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});