define("ember-svg-jar/inlined/icn-sensor-ph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 24c-3.88 0-7-3.1-7-6.94 0-1.29.36-2.51.99-3.53.12-.24.28-.43.44-.63l.04-.04c.08-.12.16-.2.24-.27L15 6l5.34 6.55c.04.04.08.12.12.16l.12.16c.87 1.18 1.42 2.63 1.42 4.2 0 3.79-3.12 6.93-7 6.93zm-1.29-4.17c.21.24.56.22.75-.05l3.43-4.84c.18-.25.14-.62-.07-.82a.46.46 0 00-.71.08l-3.07 4.35-1.17-1.32c-.2-.23-.52-.22-.72 0-.2.23-.19.6 0 .82l1.56 1.78z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});