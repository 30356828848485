define("ember-svg-jar/inlined/icn-menu-schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.34 24.57a1.41 1.41 0 01-1.42 1.38H9.84c-.77.01-1.41-.6-1.42-1.38v-2.01c0-.76.64-1.38 1.42-1.38h2.08c.79 0 1.42.62 1.42 1.38v2.01zm8-2.02c-.01-.77-.65-1.39-1.42-1.38h-2.08c-.77-.01-1.41.6-1.42 1.38v2.01c0 .76.64 1.38 1.42 1.38h2.08c.76 0 1.38-.61 1.4-1.38l.02-2.01zm4.46 3.41h2.08c.79 0 1.42-.62 1.42-1.38v-2.01c-.01-.77-.65-1.39-1.42-1.38H25.8c-.77-.01-1.41.61-1.42 1.38v2.01a1.4 1.4 0 001.42 1.36v.02zm-13.89 3.2H9.83c-.38-.01-.75.13-1.02.39s-.42.61-.42.98v2.01c0 .76.64 1.38 1.42 1.38h2.08c.38.01.75-.13 1.03-.39.27-.26.42-.61.43-.98v-2.01c0-.37-.15-.72-.42-.98-.28-.26-.64-.4-1.02-.4zm8 0h-2.08c-.38-.01-.75.13-1.02.39s-.42.61-.42.98v2.01c0 .76.64 1.38 1.43 1.38h2.08c.79 0 1.42-.62 1.42-1.38v-2.01c-.02-.76-.65-1.37-1.41-1.37zM4.49 42.32h19.47c-.55-1.54-.84-3.17-.84-4.8H4.94v-20h35.25v5.39c1.72.2 3.4.69 4.96 1.45V10.64c-.01-2.4-2.02-4.35-4.49-4.35h-4.77V3.02c-.02-.97-.81-1.74-1.78-1.72h-2.78c-.47 0-.92.18-1.26.5-.33.32-.52.76-.52 1.22v3.27H15.59V3.02c-.02-.96-.81-1.73-1.77-1.72h-2.79a1.75 1.75 0 00-1.77 1.72v3.27H4.49C2.01 6.29 0 8.24 0 10.64v27.33c0 2.4 2.01 4.35 4.49 4.35zm45.4-4.8c-.01 2.99-1.23 5.84-3.39 7.91a11.766 11.766 0 01-8.17 3.28 11.74 11.74 0 01-8.17-3.28 10.995 10.995 0 01-3.39-7.91c0-2.96 1.22-5.81 3.39-7.91 2.2-2.11 5.13-3.28 8.17-3.28 3.06 0 6 1.18 8.17 3.28 2.17 2.07 3.39 4.92 3.39 7.91zm-6.5 3.08c.01-.48-.18-.93-.52-1.27l-2.65-2.57v-3.98a1.823 1.823 0 00-1.85-1.8h-.01c-1.01-.02-1.84.79-1.85 1.8v5.48l3.74 3.62c.53.5 1.32.64 2.01.36.67-.26 1.12-.91 1.13-1.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});