define("ember-svg-jar/inlined/icn-autom-heat-protection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M4.524 4.833c-1.134.78-1.876 2.124-1.876 3.653v10.496C1.341 20.206.557 21.832.557 23.615c0 3.54 2.912 6.5 6.348 6.5 3.416 0 6.202-2.942 6.202-6.5 0-1.782-.784-3.41-2.091-4.633v-4.786a6.981 6.981 0 01-2.086-.5v5.789c0 .448.175.625.394.853.22.227 1.691 1.29 1.692 3.277 0 2.288-1.848 4.333-4.111 4.333-2.264 0-4.257-1.953-4.257-4.333 0-1.9 1.412-3.078 1.692-3.277.279-.2.4-.516.4-.853 0-.469.031-7.136.043-9.94-.444-.996-.777-2.901-.259-4.712z\" fill=\"#F07048\"/><path d=\"M5.716 11.18l.025 9.513c-1.259.43-2.165 1.574-2.165 2.932 0 1.724 1.455 3.123 3.248 3.123 1.794 0 3.248-1.399 3.248-3.123 0-1.358-.906-2.503-2.165-2.932l-.02-7.562c-1.02-.671-1.18-.793-2.171-1.95z\" fill=\"#F07048\"/><path d=\"M17.985 6.689c0-.3-.244-.542-.555-.542h-1.087a.547.547 0 00-.556.542c0 .302.244.544.556.544h1.087a.548.548 0 00.555-.544m-6.341 6.275c.3 0 .542-.244.542-.555v-1.087a.547.547 0 00-.542-.556.546.546 0 00-.544.556v1.087c0 .308.242.555.544.555M7.575 6.689c0-.3-.244-.542-.555-.542H5.933a.547.547 0 00-.556.542c0 .302.244.544.556.544H7.02a.548.548 0 00.555-.544m1.037 2.726a.545.545 0 00-.776.01l-.768.768a.547.547 0 00-.01.776.546.546 0 00.778-.008l.768-.769a.548.548 0 00.008-.777m7.293-7.294a.545.545 0 00-.775.01l-.769.768a.547.547 0 00-.01.776.546.546 0 00.778-.008l.769-.768a.548.548 0 00.007-.778M8.696 3.84a.545.545 0 00-.01-.775l-.768-.769a.547.547 0 00-.776-.01.546.546 0 00.008.778l.769.769a.548.548 0 00.777.007m7.334 7.336a.545.545 0 00-.009-.776l-.768-.768a.547.547 0 00-.776-.01.546.546 0 00.008.778l.768.768a.548.548 0 00.778.008m-4.39-7.45c-1.655 0-2.997 1.327-2.997 2.964 0 1.635 1.342 2.965 2.997 2.965 1.658 0 3-1.33 3-2.965 0-1.637-1.342-2.964-3-2.964m.003-1.064c.3 0 .542-.244.542-.555V1.02a.547.547 0 00-.542-.556.546.546 0 00-.544.556v1.087c0 .308.242.555.544.555\" fill=\"#F4AE4E\"/></g>",
    "attrs": {
      "viewBox": "0 0 18 31",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});