define("ember-svg-jar/inlined/icn-schedule-week", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M27 5.7C23.4 2.2 18.5.6 13.6 1.3c-.4 0-.7.2-.9.5-.2.3-.3.6-.3 1 .1.4.2.7.5.9.4.2.7.3 1.1.3 4.1-.6 8.2.7 11.1 3.6 2.5 2.4 3.8 5.6 3.8 9 0 3.4-1.4 6.6-3.8 9-5.1 5-13.4 5-18.5 0-2.5-2.4-3.8-5.6-3.8-9 0-3.4 1.3-6.6 3.8-9V11c0 .7.6 1.3 1.4 1.3s1.4-.6 1.4-1.3V4.6c0-.4-.1-.7-.4-.9-.2-.2-.6-.4-1-.4H1.6C.9 3.3.2 3.9.2 4.6.1 5.4.8 6 1.5 6h2.9C1.5 8.9 0 12.6 0 16.6c0 4.1 1.6 8 4.6 10.9 3 2.9 7 4.5 11.2 4.5S24 30.4 27 27.5s4.6-6.8 4.6-10.9c.1-4.1-1.6-8-4.6-10.9z\"/><path d=\"M21.1 22.7l-5.4-5.2v-10c0-.7-.6-1.3-1.4-1.3S13 6.7 13 7.5v11.1l6.2 6c.3.2.6.4 1 .4s.7-.1 1-.4c.3-.3.4-.6.4-.9-.1-.4-.3-.8-.5-1zm32.1-4H33.8v.2c-.1.9-.3 1.8-.5 2.8l-.1.3h2.3v7.4h-5.9v-.8l-.4.5c-1.1 1.2-2.4 2.3-3.8 3.2l-.7.5h1.6v8H19v-6h-.3c-1 .2-1.9.2-2.8.3h-.2v13.4c0 2.2 1.8 4 4 4h33.4c2.2 0 4-1.8 4-4V22.8c.1-2.3-1.7-4.1-3.9-4.1zM48 29.4V22h5.2c.4 0 .7.3.7.7v6.7H48zm0 11.3v-8h5.9v8H48zm5.9 7.8c0 .4-.3.7-.7.7H48V44h5.9v4.5zM44.7 44v5.1h-5.9V44h5.9zm0-11.3v8h-5.9v-8h5.9zm0-10.6v7.4h-5.9v-7.4h5.9zM35.5 44v5.1h-5.9V44h5.9zm0-11.3v8h-5.9v-8h5.9zM26.3 44v5.1h-6.6c-.4 0-.7-.3-.7-.7V44h7.3z\"/><path d=\"M63.3 5.5h-7.1V2.8c0-1.5-1.3-2.8-2.9-2.8h-2.2c-1.6 0-2.9 1.3-2.9 2.8v2.7H29.9l.3.4c.7.8 1.2 1.7 1.6 2.5l.1.1h16.8v3.1c0 1.5 1.3 2.8 2.9 2.8h1.8c1.6 0 2.9-1.3 2.9-2.8v-3h7.5c.6 0 1.1.5 1.1 1v49.3c0 .6-.5 1-1.1 1h-54c-.6 0-1.1-.5-1.1-1V33.6l-.1-.1c-.9-.4-1.7-.9-2.5-1.4l-.4-.3v27c0 2.2 1.9 4.1 4.2 4.1h53.6c2.3 0 4.2-1.8 4.2-4.1V9.6c-.3-2.3-2.1-4.1-4.4-4.1zM53.1 3v8.4h-1.7V3h1.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 67.4 62.9"
    }
  };
});