define("ember-svg-jar/inlined/icn-chart-scrollbar-buttonH", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<use xlink:href=\"#_Image1\" width=\"35\" height=\"35\"/><circle cx=\"17.5\" cy=\"17.5\" r=\"15.5\" fill=\"#fff\"/><circle cx=\"17.589\" cy=\"17.123\" r=\"12.721\" fill=\"#dcdbd9\" transform=\"matrix(.88097 0 0 .87963 2.005 2.438)\"/><path d=\"M22.6 19.5c0 .6-.4 1-.9 1h-8.2c-.5 0-.9-.4-.9-1s.4-1 .9-1h8.2c.4 0 .9.5.9 1zm0-4c0 .6-.4 1-.9 1h-8.2c-.5 0-.9-.4-.9-1s.4-1 .9-1h8.2c.4 0 .9.5.9 1z\" fill=\"#fff\" fill-rule=\"nonzero\"/><defs><image id=\"_Image1\" width=\"35\" height=\"35\" xlink:href=\"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAjCAYAAAAe2bNZAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACgklEQVRYhb2Y2XKrMBBEG+EtzvL/33lv4tjYoDwwjZpBYHBMVDUFmEXHPdLQosDyVrgt96McR7dd9OC51wXbD7Kvz4gWjQX3Z4Hdg1GIEsBGorQIcn0DoLa4SdRzoKZgqEAJYAtgZ7G3463AME2EuVpcAFQWV4HKAo3BFEhK7AG8WBzseIekDlPFFFGVymDOAL4tLkhKDYA2EyA7AzgCeLXtwRTxIGwe6GowVPNkUFUOyMMQZG+dvwF4dyCaGmAIw+dqahkbuffigRRGFTkaxIcBHdyDptILucYPfP0TEU4hhQl2/GIAHwbkQea2YiQIotO/VhifnnckRbaYVmMOVLDnKAinfQdVysUEoSJHe8BSRcaAFCwH1M0In55XtGOnxO9BFEgVJkxXf1jWdSozNc9QJAfElB2sv731H9ghixsL2jMVyQHxz2sBLagMyztB1lBFYTQbO+s7aJp8ZV2z6eumK6RBTqytSA6IQpSwNKk/uVdhnwky6De4k3+liocqgGSMosRftl6/Wg31XbE2lLenDYBIGFrFURe2EpDa1BjQt4qjLmwFEDVg3eugQfKs9BdrKqSKqD9umKYbWudFA72mOlSFHrmy/ntpqtD60zNJVwCiKvTGNOk1gEZLP4uQro2eWQTVw5wA/Lf4NrhYuhsUyC/SfgPEqXxDq8gngH8AviDDwsNEDM20qvMIkCqiIJ9oVek5vdzNbH5drb/PgfBjREFOSGMTOZiYCQ/oW27dpGpwYniQwawdU0bLdI3+q0JfGbmyzgJamRpfaAfqJAiQX95qHVDTfEbyrEvW2lxnn3Fnrb3kK8QWyScv+Qqhhe2hrxD+/KPfZ67ov4QxBgIAPxzjK0anEJJDAAAAAElFTkSuQmCC\"/></defs>",
    "attrs": {
      "viewBox": "0 0 35 35",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});