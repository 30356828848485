define("ember-svg-jar/inlined/icn-delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#F07048\" d=\"M3.946.652a.905.905 0 00-.898.898v.385H.611a.385.385 0 100 .77h8.202l-.248 8.583c-.003.091-.05.14-.129.14H2.28c-.078 0-.126-.049-.129-.14l-.256-8.583c-.004-.138-.774-.138-.77 0l.257 8.603c.013.477.407.89.898.89h6.157c.491 0 .885-.413.898-.89l.249-8.603h.521a.385.385 0 100-.77H7.667V1.55a.905.905 0 00-.898-.898H3.946zm0 .77H6.77c.077 0 .128.05.128.128v.385h-3.08V1.55c0-.078.05-.128.128-.128zm-.389 2.557a.385.385 0 00-.38.393V9.76a.385.385 0 10.77 0V4.372a.385.385 0 00-.39-.393zm1.796 0a.385.385 0 00-.38.393V9.76a.385.385 0 10.77 0V4.372a.385.385 0 00-.39-.393zm1.797 0a.385.385 0 00-.381.393V9.76a.385.385 0 10.77 0V4.372a.385.385 0 00-.39-.393z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 11 13"
    }
  };
});