define("ember-svg-jar/inlined/icn-menu-farm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M47.23 40.05c1.6-.54 2.77-2.1 2.77-3.95 0-2.29-1.8-4.16-4.01-4.16-1.28 0-2.42.63-3.16 1.6-.74-1-1.91-1.6-3.15-1.6-1.28 0-2.42.63-3.16 1.6-.74-1-1.91-1.6-3.16-1.6-2.21 0-4.01 1.87-4.01 4.16 0 1.85 1.17 3.41 2.78 3.96v1.96h-3.4V24.54h.21a1.26 1.26 0 001.23-1.28c0-8.66-6.77-15.7-15.09-15.7-1.34 0-2.63.19-3.87.53v-1.8c.02-.7-.53-1.28-1.23-1.29H4.23c-.7.01-1.25.59-1.24 1.28v7.6c-1.96 2.73-3 6.02-2.99 9.38 0 .7.55 1.28 1.23 1.28h.21v18.77c0 .71.55 1.28 1.23 1.28h46.27v-2.56h-1.73l.02-1.98zm-6.32 0a4.01 4.01 0 001.92-1.4 4.01 4.01 0 001.92 1.4v1.97h-3.84v-1.97zm-6.31 0a4.01 4.01 0 001.92-1.4 4.01 4.01 0 001.92 1.4v1.97H34.6v-1.97zM9.46 31.41l3.93 4.33-3.93 4.33v-8.66zm1.63-1.95h8.01l-4 4.41c-.01 0-4.01-4.41-4.01-4.41zm9.62 1.95v8.67l-3.93-4.33 3.93-4.34zm-5.62 6.2l4 4.41h-8.01l4.01-4.41zM8.75 7.56v1.45a15 15 0 00-3.29 2.17V7.56h3.29zm6.34 2.55c6.54 0 11.94 5.21 12.56 11.86H2.53c.62-6.64 6.01-11.86 12.56-11.86z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});