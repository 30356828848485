define("ember-svg-jar/inlined/icn-sensor-wind-direction", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.87 23.84c-4.81 0-8.71-3.91-8.71-8.71s3.91-8.71 8.71-8.71 8.71 3.91 8.71 8.71-3.9 8.71-8.71 8.71zm0-19.22c-5.8 0-10.5 4.71-10.5 10.5s4.71 10.5 10.5 10.5 10.5-4.71 10.5-10.5-4.7-10.5-10.5-10.5z\"/><path d=\"M14.88 15.93c-.49 0-.88-.36-.88-.82 0-.45.39-.82.88-.82s.88.36.88.82c0 .46-.39.82-.88.82zm3.99-7.17c-.09-.05-.21-.03-.29.03l-5.78 5.15c-.03.03-.06.07-.07.11l-1.96 7.19c-.03.09.02.19.11.24.03.02.08.03.12.03.06 0 .13-.03.17-.06l5.78-5.15c.03-.03.06-.07.07-.11L18.97 9a.202.202 0 00-.1-.24z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});