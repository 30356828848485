define("ember-svg-jar/inlined/icn-sensor-temperature-full", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.04 10.75c0 .24.12.48.24.6.72.6 1.21 1.45 1.21 2.3 0 1.69-1.33 3.02-2.9 3.02s-3.02-1.45-3.02-3.02c0-.85.48-1.69 1.21-2.3.12-.12.24-.36.24-.6V3.14c0-.85.6-1.57 1.45-1.57s1.45.72 1.45 1.57l.12 7.61zm1.45-7.73C7.49 1.33 6.16 0 4.47 0S1.45 1.33 1.45 3.02v7.25C.48 11.12 0 12.2 0 13.53 0 15.95 2.05 18 4.47 18s4.35-2.05 4.35-4.47c0-1.21-.6-2.42-1.45-3.26l.12-7.25z\"/><path d=\"M5.17 3.37c0-.33-.05-1.02-.65-1.02-.62 0-.69.58-.69 1.02v8.53c-.67.22-1.23.89-1.23 1.78 0 1 .78 1.9 1.9 1.9s1.9-.89 1.9-1.9c0-.78-.56-1.45-1.23-1.78V3.37z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 8.82 18"
    }
  };
});