define("ember-svg-jar/inlined/icn-hortau-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.22 0l-7.75.05c-.09 5.06-.68 7.55-2.82 11.31C17.84 6.9 21.77 2.69 24.22 0zM13.44 0H13c-1.96 0-3.87.5-5.54 1.27 3.25 9.49 2.28 12.66-1.24 17.93C15.2 9.36 13.61.89 13.44 0z\" fill=\"#48CD83\"/><path d=\"M4.3 22.54c2.33 2.24 5.07 3.08 8.59 3.08 7.14.01 13.05-5.73 13.05-12.8V1.99c-2.72 2.43-6.96 6.53-11.35 10.57L4.3 22.54z\" fill=\"#00A2E1\"/><path d=\"M5.19 2.71C2.17 5.05 0 8.94 0 13.04c0 3.45 1.41 6.65 3.66 8.95 3.97-4.3 4.22-14.98 1.53-19.28z\" fill=\"#48CD83\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25.93 25.62"
    }
  };
});