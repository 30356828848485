define("ember-svg-jar/inlined/icn-goal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#6ED191\" fill-rule=\"evenodd\" d=\"M22.99 13.667c-.542-.799-1.445-1.299-2.493-1.299-.904 0-1.699.367-2.241 1l-.614-1.034c-.651-1.034-1.771-1.7-3-1.867V6.534h6.867c.29 0 .58-.167.687-.434a.636.636 0 00-.218-.733l-2.855-2.1 2.855-2.1a.591.591 0 00.218-.733C22.087.167 21.834 0 21.509 0h-7.59c-.4 0-.724.3-.724.667v9.832c-1.228.201-2.35.868-3 1.9l-.543.734a2.972 2.972 0 00-2.025-.766c-1.012 0-1.951.5-2.493 1.299L.108 21.4a.568.568 0 000 .667c.144.2.362.333.614.333H3.94L2.963 24a.722.722 0 000 .667c.109.2.362.333.614.333h20.497c.253 0 .505-.133.614-.333.109-.2.144-.466 0-.667l-.976-1.6h3.579c.252 0 .505-.133.614-.333.144-.201.108-.467 0-.667l-4.914-7.733zm-8.386-12.4h4.809L17.46 2.701a.678.678 0 00-.29.533c0 .2.11.4.29.534l1.952 1.434-4.809-.002V1.267zm-12.58 19.8L6.4 14.334c.253-.4.723-.633 1.228-.633.506 0 .976.233 1.229.633l.037.034-4.12 6.699H2.023zm2.857 2.6L11.424 13c.543-.8 1.446-1.3 2.494-1.3 1.013 0 1.952.466 2.459 1.3l6.543 10.666H4.88zm18.11-2.6l-3.94-6.4.218-.333c.252-.4.723-.633 1.228-.633.506 0 .976.233 1.229.633l4.374 6.733h-3.11z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 28 25"
    }
  };
});