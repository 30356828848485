define("ember-collection/utils/style-properties", ["exports", "@ember/string"], function (_exports, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cssProperty = cssProperty;
  _exports.styleProperty = styleProperty;
  const stylePrefixes = ['webkit', 'Webkit', 'ms', 'Moz', 'O'];
  const cssPrefixes = ['-webkit-', '-ms-', '-moz-', '-o-'];
  const style = typeof document !== 'undefined' && document.documentElement && document.documentElement.style;
  function findProperty(property, css) {
    let prop = css ? (0, _string.camelize)(property) : property;
    if (prop in style) {
      return property;
    }
    let capitalized = (0, _string.capitalize)(prop);
    for (let i = 0; i < stylePrefixes.length; i++) {
      let prefixed = stylePrefixes[i] + capitalized;
      if (prefixed in style) {
        return css ? cssPrefixes[i] + property : prefixed;
      }
    }
  }
  function styleProperty(prop) {
    return findProperty(prop, false);
  }
  function cssProperty(cssProp) {
    return findProperty(cssProp, true);
  }
});