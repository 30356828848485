define("ember-svg-jar/inlined/icn-schedule-print-autom-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.938 10.164l-2.355-1.001a7.59 7.59 0 00-.73-1.774l.956-2.331a.843.843 0 00-.184-.916l-.789-.788a.843.843 0 00-.918-.182l-2.328.955a7.579 7.579 0 00-1.777-.73l-.977-2.332a.841.841 0 00-.777-.517h-1.12a.841.841 0 00-.777.515L9.163 3.42a7.59 7.59 0 00-1.774.731l-2.333-.957a.843.843 0 00-.916.184l-.788.788a.843.843 0 00-.184.916l.955 2.329a7.579 7.579 0 00-.73 1.778l-2.33.975a.841.841 0 00-.515.778v1.121c0 .338.201.642.515.776l2.357.998c.17.634.414 1.216.729 1.777l-.955 2.328a.843.843 0 00.184.916l.789.789a.842.842 0 00.915.184l2.329-.955c.56.315 1.145.558 1.776.729l.977 2.33a.843.843 0 00.778.518h1.12a.841.841 0 00.777-.513l.999-2.359a7.602 7.602 0 001.774-.73l2.33.956a.843.843 0 00.916-.184l.789-.789a.843.843 0 00.184-.916l-.955-2.328a7.579 7.579 0 00.73-1.777l2.33-.977a.843.843 0 00.517-.777v-1.12a.843.843 0 00-.515-.775zM11.5 15.515a4.015 4.015 0 110-8.028 4.015 4.015 0 010 8.03z\" fill=\"none\" stroke=\"#676767\" stroke-width=\"1.095\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});