define("ember-svg-jar/inlined/icn-sensor-rain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M21.88 9.4c-.64-2.24-2.74-3.9-5.23-3.9-2.19 0-4.06 1.25-4.92 3.06-.11 0-.22-.02-.33-.02-1.79 0-3.25 1.38-3.33 3.11-.11 0-.22-.02-.33-.02-1.77 0-3.22 1.4-3.22 3.15 0 1.73 1.44 3.15 3.22 3.15h13.38c2.43 0 4.39-1.92 4.39-4.29-.01-2.14-1.58-3.89-3.63-4.24zM9.31 19.44c-.38-.15-.81.02-.96.39l-1.54 3.7c-.16.37.02.78.4.93.09.04.18.04.27.04.29 0 .56-.17.67-.43l1.54-3.7a.703.703 0 00-.38-.93zm3.74 0a.744.744 0 00-.96.39l-1.54 3.7c-.16.37.02.78.4.93.09.04.18.04.27.04.29 0 .56-.17.67-.43l1.54-3.7c.18-.37 0-.78-.38-.93zm3.75 0a.744.744 0 00-.96.39l-1.54 3.7c-.16.37.02.78.4.93.09.04.18.04.27.04.29 0 .56-.17.67-.43l1.54-3.7c.18-.37 0-.78-.38-.93zm3.75 0a.744.744 0 00-.96.39l-1.54 3.7c-.16.37.02.78.4.93.09.04.18.04.27.04.29 0 .56-.17.67-.43l1.54-3.7c.18-.37 0-.78-.38-.93z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});