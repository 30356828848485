define("ember-svg-jar/inlined/icn-sensor-relative-humidity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.24 13.92c.71-.66 1.16-1.57 1.16-2.56 0-.83-.32-1.6-.83-2.22l-.07-.09c-.02-.02-.05-.06-.07-.08L20.32 5.5l-3.08 3.49c-.05.04-.09.08-.14.15l-.02.02c-.09.1-.18.21-.25.33-.12.17-.21.36-.29.55-.54-.08-1.09-.14-1.65-.14-5.83 0-10.56 4.62-10.56 10.31 0 .32.26.57.59.57h7.7c.26.99 1.18 1.72 2.27 1.72 1.08 0 1.98-.73 2.24-1.72h7.74c.32 0 .59-.26.59-.57-.01-2.37-.84-4.55-2.22-6.29zm-6.99 5.92c-.11.11-.18.25-.18.4-.02.62-.53 1.11-1.17 1.11-.66 0-1.17-.51-1.17-1.15 0-.64.52-1.15 1.17-1.15.2 0 .39.04.55.13.15.08.32.09.48.04l1.69-.62-1.37 1.24zm1.95-.2l2.37-2.17a.582.582 0 00.13-.66.6.6 0 00-.6-.33c-.05.01-.09.02-.14.04l-4.31 1.57c-.25-.09-.5-.18-.77-.18-1.08 0-2 .74-2.26 1.72H5.56c.08-1.27.43-2.47.99-3.55l1.02.56c.14.09.3.12.46.08.16-.04.3-.14.38-.28.08-.14.1-.3.05-.46a.553.553 0 00-.31-.34l-1.02-.56c1.57-2.29 4.18-3.84 7.18-4.02v1.4c.002.319.264.58.583.58h.014a.586.586 0 00.583-.58v-1.4c.27.02.53.06.78.09 0 .07-.01.13-.01.2 0 2.04 1.82 3.68 4.07 3.68.7 0 1.36-.18 1.93-.46.14.18.29.36.42.55l-1.04.57c-.14.08-.23.2-.28.35-.04.15-.02.3.06.44.165.279.53.373.81.21l1.04-.57c.54 1.07.88 2.26.96 3.52H18.2z\" fill-rule=\"nonzero\"/><ellipse cx=\"14.89\" cy=\"20.21\" rx=\".59\" ry=\".57\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});