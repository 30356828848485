define("ember-svg-jar/inlined/icn-menu-automation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path d=\"M6.383.893C6.383.455 6.936.1 7.616.1s1.233.355 1.233.793v6.89c0 .439-.552.793-1.233.793-.68 0-1.233-.354-1.233-.792V.893z\"/><path d=\"M2.411 2.9A1.232 1.232 0 114.16 4.637c-1.903 1.915-1.904 5.02-.003 6.92a4.911 4.911 0 006.92-.001c1.899-1.9 1.898-5.004-.005-6.919a1.232 1.232 0 111.749-1.738c2.858 2.876 2.857 7.542-.003 10.402a7.354 7.354 0 01-5.203 2.146 7.345 7.345 0 01-5.2-2.144C-.446 10.44-.447 5.775 2.411 2.899z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17"
    }
  };
});