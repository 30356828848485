define("ember-svg-jar/inlined/icn-sensor-leaf-wetness-hours", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill-rule=\"evenodd\"><path fill-rule=\"nonzero\" d=\"M8.6 12.82H1.4a.6.6 0 01-.424-1.026l4.977-4.975L.975 1.844A.601.601 0 011.401.82h7.2a.6.6 0 010 1.2H2.849l4.377 4.374a.6.6 0 010 .848l-4.377 4.377H8.6c.33 0 .6.27.6.6 0 .33-.27.6-.6.6z\"/><path d=\"M15.524 6.504c-.728 0-1.315-.634-1.315-1.42a1.46 1.46 0 01.267-.851l.008-.008c.015-.025.03-.04.044-.057l.996-1.348 1.004 1.34c.007.008.014.024.022.032l.022.032c.164.241.268.538.268.859 0 .78-.587 1.421-1.316 1.421zm2.106 3.684a1.832 1.832 0 01-1.843-1.826 1.766 1.766 0 01.375-1.094l.01-.01c.022-.032.042-.052.063-.073l1.395-1.733 1.405 1.723c.01.01.021.03.032.04l.03.042c.23.31.376.691.376 1.104a1.838 1.838 0 01-1.843 1.827zm-3.623.553c-.74-.827-1.774-1.734-2.507-2.359a.199.199 0 01-.072-.154c-.005-.135.08-.227.214-.232a.384.384 0 01.157.04l.022-.001c1.032.884 1.86 1.64 2.461 2.293l.124-.274s0-.022.02-.045c.02-.068.04-.159.06-.227-.001-.022.02-.046.02-.068.019-.068.016-.135.037-.203a.163.163 0 01.019-.09 1.264 1.264 0 01-.033-.292c-.06-1.749-1.915-2.604-3.65-2.566C9.302 6.596 8.42 6.2 8.42 6.2s.302 2.279.813 3.54c.585 1.44 1.583 1.988 2.939 1.94.089-.002.178-.005.266-.03l.022-.002a.75.75 0 00.22-.052l.023-.001c.153-.05.308-.1.46-.173.132-.072.263-.144.393-.238.463.5.766.894.772 1.073l.667-.024c-.034-.336-.432-.905-.987-1.492z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 13"
    }
  };
});