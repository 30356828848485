define("ember-svg-jar/inlined/icn-thunderstorm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#1196D9\" d=\"M16.171 3.533C15.591 1.503 13.694 0 11.436 0 9.459 0 7.761 1.132 6.981 2.77c-.1 0-.2-.019-.3-.019-1.618 0-2.937 1.249-3.017 2.81-.1 0-.2-.02-.3-.02C1.767 5.54.447 6.809.447 8.39c0 1.56 1.299 2.849 2.917 2.849h12.108c2.197.001 3.975-1.736 3.975-3.882 0-1.93-1.419-3.512-3.276-3.825z\"/><path fill=\"#F4AE4E\" d=\"M6.52 8.566a.266.266 0 00-.22.166l-2.08 5.59c-.06.157.076.354.244.354h1.32l-1.824 4.98a.268.268 0 00.111.308c.1.062.243.042.324-.044l6.5-7.02a.265.265 0 00.045-.279.264.264 0 00-.236-.155H8.478l1.3-3.55c.057-.157-.077-.35-.244-.35H6.52z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
});