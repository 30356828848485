define("ember-svg-jar/inlined/icn-notification-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.495 22.625c0 .36.184.72.368.9 1.102.9 1.852 2.176 1.852 3.45 0 2.536-2.036 4.531-4.44 4.531-2.403 0-4.623-2.175-4.623-4.53 0-1.275.735-2.535 1.853-3.45.183-.18.367-.54.367-.9V11.208c0-1.275.919-2.356 2.22-2.356 1.301 0 2.22 1.08 2.22 2.356l.183 11.416zm2.22-11.596c0-2.536-2.036-4.53-4.623-4.53s-4.623 1.994-4.623 4.53v10.876c-1.485 1.275-2.22 2.896-2.22 4.89 0 3.631 3.138 6.707 6.843 6.707 3.704 0 6.659-3.076 6.659-6.706 0-1.815-.919-3.63-2.22-4.89l.184-10.877z\" fill-rule=\"nonzero\"/><path d=\"M21.163 11.554c0-.495-.076-1.53-.995-1.53-.949 0-1.056.87-1.056 1.53V24.35c-1.026.33-1.883 1.335-1.883 2.67 0 1.5 1.194 2.85 2.909 2.85 1.714 0 2.908-1.335 2.908-2.85 0-1.17-.857-2.175-1.883-2.67V11.554z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});