define("ember-svg-jar/inlined/icn-menu-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.927 20.198l2.72 2.003 2.115-2.16-1.963-2.776c.453-.77.905-1.849 1.058-2.775l3.322-.615V10.79l-3.322-.615c-.305-.923-.605-2.002-1.058-2.775l1.965-2.775-2.12-2.158-2.717 2.003c-.757-.462-1.814-.926-2.722-1.079L13.6 0h-3.026l-.603 3.39c-.905.31-1.964.617-2.72 1.079L4.533 2.466l-2.12 2.158L4.378 7.4c-.452.773-.905 1.852-1.055 2.775L0 10.794v3.086l3.325.615c.302.923.602 2.002 1.055 2.775l-1.965 2.775 2.12 2.158 2.719-2c.755.461 1.812.925 2.72 1.078l.607 3.39h3.022l.602-3.39c.908-.155 1.815-.619 2.722-1.08v-.003zm-9.37-7.863c0-2.622 1.964-4.625 4.534-4.625s4.532 2.003 4.532 4.625c0 2.621-1.965 4.624-4.532 4.624-2.572 0-4.535-2.003-4.535-4.624zm35.34 27.952l5.439 1.541L50 37.512l-4.684-2.622c.15-1.079.302-2.313.15-3.39L50 28.878l-1.814-4.315-4.532 1.234a12.093 12.093 0 00-2.57-2.93l1.21-4.472-4.232-1.696-2.115 3.701c-1.36-.31-2.87-.31-4.384-.155l-2.115-3.699-4.077 2.003 1.06 4.009c-1.212.926-2.27 2.005-3.025 3.239l-4.081-1.079-1.665 4.316 3.93 2.313c-.15 1.388 0 2.62.15 4.01l-4.08 2.464 1.812 4.316 4.99-1.388c.754.926 1.507 1.698 2.567 2.313l-1.36 5.24L29.9 50l2.72-4.935c.904.155 1.964 0 2.87-.153L38.36 50l4.23-1.85-1.51-5.55c.457-.77 1.214-1.54 1.816-2.313zm-15.414-7.863c0-3.39 2.72-6.165 6.045-6.165 3.322 0 6.042 2.775 6.042 6.165 0 3.392-2.72 6.167-6.042 6.167-3.325.002-6.045-2.773-6.045-6.167z\"/>",
    "attrs": {
      "viewBox": "0 0 50 50",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});