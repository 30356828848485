define("ember-svg-jar/inlined/icn-menu-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.84 49.83c-10.33 0-18.65-8.56-18.65-19.18 0-3.58.95-6.93 2.63-9.75.32-.65.74-1.19 1.16-1.74l.11-.11c.21-.33.42-.54.63-.76L24.84.08l14.23 18.1c.11.11.21.33.32.43l.32.43c2.32 3.25 3.8 7.26 3.8 11.6-.01 10.52-8.34 19.19-18.67 19.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 50 50"
    }
  };
});