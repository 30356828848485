define("ember-svg-jar/inlined/icn-live-weather-wind", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5 13.8H6.6c-.3 0-.5.2-.5.5s.2.5.5.5h12.9c1.9 0 3.4 1.6 3.4 3.4s-1.6 3.4-3.4 3.4c-1.9 0-3.4-1.6-3.4-3.4 0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 2.5 2 4.5 4.5 4.5s4.5-2 4.5-4.5c-.1-2.4-2.1-4.4-4.6-4.4z\"/><path d=\"M11.6 15.9h-5c-.3 0-.5.2-.5.5-.1.4.1.6.5.6h4.9c.8 0 1.5.6 1.5 1.5 0 .8-.6 1.5-1.5 1.5-.8 0-1.5-.6-1.5-1.5 0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 1.4 1.1 2.5 2.5 2.5s2.5-1.1 2.5-2.5-1.1-2.6-2.4-2.6zm-5.5-3.6c0 .3.2.5.5.5h10.9c1.4 0 2.5-1.1 2.5-2.5s-1-2.6-2.4-2.6-2.5 1.1-2.5 2.5c0 .3.2.5.5.5s.5-.2.5-.5c-.1-.8.6-1.5 1.5-1.5.8 0 1.5.6 1.5 1.5 0 .8-.6 1.5-1.5 1.5h-11c-.2.1-.5.3-.5.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});