define("ember-svg-jar/inlined/icn-sensor-time-since-last-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M24.533 9.105l.692-.354c.395-.224 1.265.27 1.118.89l-.691 2.486c-.106.324-.587.589-.927.524l-2.487-.734c-.504-.143-.594-1.167-.18-1.428l.923-.519c-3.59-4.62-8.304-5.378-12.682-3.144C5.921 9.061 4.273 15.24 6.757 19.61s8.218 6.16 12.78 3.654c1.596-.877 2.857-2.135 3.91-3.962.167-.288.751-.379 1.066-.145.314.234.576.641.39 1.01-1.018 2.01-2.613 3.664-4.53 4.66-5.248 2.726-11.823 1.418-15.163-4.41-3.339-5.83-.362-12.5 4.214-15.116 4.577-2.616 11.43-1.996 15.11 3.804z\"/><path d=\"M10.108 10.187c.27.008.527.121.716.315 1.646 1.65 3.15 3.268 4.73 4.882l1.86-1.49a1.041 1.041 0 111.3 1.626l-2.601 2.08a1.04 1.04 0 01-1.388-.08c-1.87-1.876-3.547-3.717-5.375-5.55a1.04 1.04 0 01.758-1.783z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30"
    }
  };
});