define("ember-svg-jar/inlined/icn-trend-stable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter x=\"-22.1%\" y=\"-22.1%\" width=\"144.3%\" height=\"144.3%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feMorphology radius=\"1.5\" operator=\"dilate\" in=\"SourceAlpha\" result=\"shadowSpreadOuter1\"/><feOffset in=\"shadowSpreadOuter1\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"2\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feComposite in=\"shadowBlurOuter1\" in2=\"SourceAlpha\" operator=\"out\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0\" in=\"shadowBlurOuter1\"/></filter><circle id=\"b\" cx=\"18.86\" cy=\"16.941\" r=\"16.941\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"scale(1 -1) rotate(17 152.028 13.456)\"><use fill=\"#000\" filter=\"url(#a)\" xlink:href=\"#b\"/><use stroke=\"#FFF\" stroke-width=\"3\" fill=\"#98948E\" xlink:href=\"#b\"/></g><path d=\"M36.116 21.481c.536.542.532 1.42-.007 1.962l-6.437 6.378c-1.49 1.485-3.726-.743-2.236-2.228l2.785-2.776c.464-.463.308-.837-.34-.837l-6.832-.019-5.19 4.175-5.663-6.992-5.545 4.497-.217-3.92 6.21-5.046 5.69 7.016 3.548-2.86 7.998-.002c.652 0 .808-.373.341-.838l-2.785-2.774c-1.49-1.486.746-3.714 2.236-2.228l6.444 6.492z\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "45",
      "height": "45",
      "viewBox": "0 0 45 45",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
});