define("ember-svg-jar/inlined/icn-notification-connection-lost", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M18.277 10.291h3.589l-.425 12.146-2.73-.053-.434-12.093z\"/><circle cx=\"20.025\" cy=\"26.05\" r=\"1.767\" transform=\"translate(-5.122 -5.127) scale(1.25575)\"/>",
    "attrs": {
      "viewBox": "0 0 40 40",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});