define("ember-svg-jar/inlined/icn-spin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.885 7.382c-.469.36-.542 1.045-.181 1.514a9.736 9.736 0 012.093 6.061c0 5.411-4.402 9.849-9.814 9.849-.505 0-1.047-.037-1.552-.109l1.046-1.01c.432-.432.432-1.119 0-1.514-.433-.433-1.12-.433-1.516 0l-3.608 3.535 3.571 3.931c.217.252.505.361.794.361.252 0 .505-.109.721-.289.433-.397.47-1.082.072-1.515l-1.116-1.335c.505.073 1.046.11 1.588.11C18.586 26.971 24 21.596 24 14.957c0-2.706-.903-5.268-2.563-7.396-.397-.468-1.082-.578-1.552-.179zM3.284 22.642c.218 0 .47-.073.65-.217.47-.361.579-1.047.218-1.515a9.651 9.651 0 01-2.022-5.953c0-5.411 4.402-9.848 9.853-9.848.468 0 .939.035 1.406.107l-1.047 1.011c-.431.432-.431 1.119 0 1.514.219.218.506.325.76.325.289 0 .541-.109.758-.324l3.608-3.536-3.537-3.86a1.083 1.083 0 00-1.515-.071 1.083 1.083 0 00-.072 1.514l1.155 1.263a10.33 10.33 0 00-1.481-.109C5.415 2.944 0 8.318 0 14.957c0 2.634.83 5.16 2.454 7.252.184.277.498.44.83.433z\" fill=\"#00a2e1\"/>",
    "attrs": {
      "viewBox": "0 0 24 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});