define("ember-svg-jar/inlined/icn-printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#65615a\"><path d=\"M23.369 3.733H18.42V0H6.58v3.733H1.631C.737 3.733 0 4.48 0 5.386v8.294c0 .906.737 1.653 1.631 1.653h3.027L3.632 20h17.763l-1.053-4.667h3.027c.894 0 1.631-.747 1.631-1.653V5.386c0-.906-.737-1.653-1.631-1.653zM8.026 1.467h8.948v2.24H8.026v-2.24zM5.447 18.533l1.58-7.12h10.947l1.579 7.12H5.447zm18.106-4.853c0 .106-.08.187-.184.187h-3.342l-.869-3.947H5.868L5 13.867H1.628a.182.182 0 01-.181-.181v-8.3c0-.106.08-.186.184-.186h21.715a.18.18 0 01.18.18v8.3h.027z\"/><path d=\"M17.448 12.8H8.359l-.294 1.6h9.677l-.294-1.6zm-.12 2.4H7.569l-.31 1.6h10.483l-.311-1.6h-.103zm3.15-8.8h-1.44c-.288 0-.49.279-.49.591v.418c0 .348.231.591.49.591h1.44c.288 0 .49-.279.49-.591V6.99c0-.312-.23-.591-.49-.591V6.4zm1.296.8c0 1.067-.806 1.067-.806 0s.806-1.067.806 0\"/></g>",
    "attrs": {
      "viewBox": "0 0 25 20",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});