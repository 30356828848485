define("ember-svg-jar/inlined/icn-schedule-today", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<style>.st0{fill:#bbb8b3}</style><g id=\"icn_schedule_today\" transform=\"translate(89 6)\"><path id=\"Fill-1\" class=\"st0\" d=\"M-65.67-3.8h-2.62v-.68c0-.82-.68-1.49-1.53-1.49h-.86c-.84 0-1.53.67-1.53 1.49v.68h-8.21v-.68c0-.82-.68-1.49-1.53-1.49h-.86c-.84 0-1.53.67-1.53 1.49v.68h-2.62c-1.13 0-2.04.89-2.04 1.99v20.63c0 .54.19 1.03.53 1.37.34.35.82.54 1.35.54l13.9.06v-1.95h-13.86c-.02 0-.03-.01-.03-.01l-.03-5.81V-1.8s.01-.01.03-.01h2.77v.85c0 .82.68 1.49 1.53 1.49h.71c.84 0 1.53-.67 1.53-1.49v-.85h8.51v.85c0 .82.68 1.49 1.53 1.49h.71c.84 0 1.53-.67 1.53-1.49v-.85h2.77c.02 0 .02.01.03.01v20.63s-.01.01-.03.01h-4.04v1.97l3.89.02c1.13 0 2.04-.89 2.04-1.99V-1.8c0-1.1-.91-2-2.04-2\"/><path id=\"Fill-4\" class=\"st0\" d=\"M-69.24 17.73c.09.09.17.18.26.26l.01.01c.17.17.43.28.67.28.25 0 .52-.11.68-.28.19-.2.28-.44.27-.67a.973.973 0 00-.28-.66l-2.8-2.78-.13-.13-.25-.25-.01-.01c-.03-.03-.07-.06-.11-.09-.01 0-.01-.01-.02-.01h-.01l-.01-.01c-.01 0-.01-.01-.02-.01-.01-.01-.02-.01-.02-.01a.903.903 0 00-.49-.13.96.96 0 00-.67.28l-2.79 2.78-.13.13c-.09.08-.18.17-.26.26l.16.16-.16-.16c-.17.17-.28.42-.28.67 0 .25.11.52.29.68.2.18.44.28.68.27.26-.01.49-.11.66-.28l1.57-1.56v6.57c0 .25.1.5.28.67.17.17.43.28.65.28h.03a.96.96 0 00.67-.28c.18-.19.27-.42.27-.66v-6.57l1.17 1.16c.03 0 .08.05.12.09\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 26 30"
    }
  };
});