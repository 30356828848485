define("ember-svg-jar/inlined/icn-sensor-wind-speed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M23.69 10.64l-11.6-2.37c-.1-.02-.2 0-.28.05l-4.52 2.57V8.65a.892.892 0 00.48-.78c0-.48-.4-.87-.88-.87-.49 0-.89.39-.89.87 0 .34.2.63.49.78v15.97c0 .21.18.39.39.39.21 0 .39-.17.39-.39V13.35l4.43 2.51c.02.02.03.04.05.05.07.06.16.09.25.09.03 0 .05 0 .08-.01l11.6-2.33c.18-.04.32-.2.32-.38v-2.26c0-.18-.13-.34-.31-.38zm-9.33-1.12l1.97.4v4.42l-1.97.4V9.52zm4.53.93l2.36.48v2.43l-2.36.47v-3.38zM7.28 11.77l4.33-2.46v5.6l-4.33-2.46v-.68z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});