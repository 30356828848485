define("ember-svg-jar/inlined/icn-menu-issue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.472 17.057l1.38-3a9.38 9.38 0 01-1.26-4.679 9.379 9.379 0 119.379 9.379 9.38 9.38 0 01-4.68-1.26l-2.999 1.38c-.18.08-.38.12-.58.12-.36 0-.7-.14-.96-.4-.4-.42-.52-1.02-.28-1.54zM9.97 2.12a7.265 7.265 0 00-7.258 7.258c0 1.384.393 2.75 1.141 3.91.15.224.169.486.056.729l-1.197 2.6 2.6-1.197a.729.729 0 01.73.056 7.271 7.271 0 003.91 1.141A7.265 7.265 0 0017.21 9.36a7.233 7.233 0 00-7.24-7.24zm-.12 9.822a1.178 1.178 0 110 2.355 1.178 1.178 0 010-2.355zM9.721 4.7h.253a1 1 0 01.999 1.05l-.227 4.47a.9.9 0 01-.898.854l-.11-.007a.909.909 0 01-.797-.857l-.219-4.461a1 1 0 01.999-1.05z\" fill=\"#FFBC61\"/>",
    "attrs": {
      "width": "20",
      "height": "19",
      "viewBox": "0 0 20 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});