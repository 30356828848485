define("ember-svg-jar/inlined/icn-arrow-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.454 7.916c-.605.617-.6 1.621.01 2.233l7.265 7.294c1.696 1.69 4.24-.846 2.545-2.536l-3.17-3.16c-.528-.527-.35-.953.388-.953H16.2c.993 0 1.8-.804 1.8-1.794s-.807-1.793-1.8-1.793H7.492c-.742 0-.92-.426-.389-.955l3.17-3.158C11.97 1.402 9.426-1.134 7.73.558L.454 7.916z\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});