define("ember-svg-jar/inlined/icn-sensor-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.35 6.55c0-2.37-1.82-4.28-4.1-4.28s-4.1 1.91-4.1 4.28v10.27c-1.28 1.2-2.05 2.79-2.05 4.54 0 3.46 2.85 6.36 6.21 6.36 3.34 0 6.07-2.88 6.07-6.36 0-1.74-.77-3.34-2.05-4.54V6.55zm-2.04 10.77c0 .33.14.63.39.84 1.05.85 1.66 1.99 1.66 3.21 0 2.32-1.82 4.24-4.02 4.24-2.24 0-4.17-1.96-4.17-4.24 0-1.22.61-2.36 1.66-3.21.25-.2.39-.51.39-.84V6.55c0-1.2.91-2.16 2.05-2.16s2.05.96 2.05 2.16v10.77z\"/><path d=\"M16.35 18.51v-2.22c0-.5-.28-1.02-1.06-1.02s-1.06.42-1.06 1.02v2.22c-1.23.42-2.12 1.54-2.12 2.87 0 1.69 1.42 3.05 3.18 3.05s3.18-1.37 3.18-3.05c0-1.33-.89-2.45-2.12-2.87\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});