define("ember-svg-jar/inlined/icn-schedule-mixed-fertigation-first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"a\" x1=\"50%\" x2=\"50%\" y1=\"100%\" y2=\"0%\"><stop offset=\"0%\" stop-color=\"#37A5CE\"/><stop offset=\"100%\" stop-color=\"#3DB5E3\"/></linearGradient><filter id=\"b\" width=\"141.7%\" height=\"132.6%\" x=\"-12.5%\" y=\"-9.8%\" filterUnits=\"objectBoundingBox\"><feOffset dx=\"3\" dy=\"3\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\" stdDeviation=\"2\"/><feColorMatrix in=\"shadowBlurOuter1\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0\"/></filter><path id=\"c\" d=\"M177.998 310C168.03 310 160 302.085 160 292.263c0-3.307.914-6.412 2.542-9.019.306-.603.711-1.104 1.12-1.605l.104-.102c.202-.301.405-.5.611-.7L177.998 264l13.728 16.735c.103.101.203.3.306.399l.306.4c2.236 3.005 3.662 6.713 3.662 10.72-.004 9.728-8.036 17.745-18.002 17.745V310z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"url(#a)\" d=\"M183.998 310C174.03 310 166 302.085 166 292.263c0-3.307.914-6.412 2.542-9.019.306-.603.711-1.104 1.12-1.605l.104-.102c.202-.301.405-.5.611-.7L183.998 264l13.728 16.735c.103.101.203.3.306.399l.306.4c2.236 3.005 3.662 6.713 3.662 10.72-.004 9.728-8.036 17.745-18.002 17.745V310z\" transform=\"translate(-155 -256)\"/><g transform=\"translate(-155 -256)\"><use fill=\"#000\" filter=\"url(#b)\" xlink:href=\"#c\"/><use fill=\"#6ED191\" xlink:href=\"#c\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "52",
      "height": "58",
      "viewBox": "0 0 52 58"
    }
  };
});