define("ember-svg-jar/inlined/icn-autom-heat-protection-disabled", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#BBB8B3\" fill-rule=\"evenodd\"><path d=\"M4.42 5.259c-1.134.78-1.876 2.124-1.876 3.653v10.495C1.237 20.631.452 22.258.452 24.041c0 3.54 2.912 6.5 6.348 6.5 3.416 0 6.203-2.942 6.203-6.5 0-1.783-.785-3.41-2.092-4.634v-4.785a6.981 6.981 0 01-2.086-.5v5.788c0 .449.176.626.395.853.22.228 1.69 1.29 1.691 3.278.001 2.287-1.847 4.333-4.111 4.333-2.263 0-4.256-1.954-4.256-4.333 0-1.9 1.412-3.079 1.691-3.278.28-.198.401-.515.401-.853 0-.468.03-7.135.042-9.94-.444-.995-.777-2.9-.258-4.711z\"/><path d=\"M5.611 11.606l.026 9.513c-1.26.43-2.165 1.574-2.165 2.931 0 1.725 1.454 3.124 3.248 3.124 1.793 0 3.248-1.399 3.248-3.124 0-1.357-.907-2.502-2.165-2.931l-.02-7.562c-1.02-.672-1.181-.793-2.172-1.951zM17.88 7.115c0-.3-.244-.542-.555-.542h-1.087a.547.547 0 00-.556.542c0 .302.244.544.556.544h1.087a.548.548 0 00.555-.544m-6.34 6.275c.3 0 .541-.244.541-.555v-1.087a.547.547 0 00-.541-.556.546.546 0 00-.545.556v1.087c0 .308.242.555.545.555M7.47 7.115c0-.3-.244-.542-.555-.542H5.828a.547.547 0 00-.555.542c0 .302.243.544.555.544h1.087a.548.548 0 00.555-.544M8.507 9.84a.545.545 0 00-.775.01l-.769.768a.547.547 0 00-.01.777.546.546 0 00.778-.008l.769-.769a.548.548 0 00.007-.777M15.8 2.547a.545.545 0 00-.775.01l-.768.768a.547.547 0 00-.01.776.546.546 0 00.778-.008l.768-.768a.548.548 0 00.008-.778M8.592 4.266a.545.545 0 00-.01-.776l-.768-.768a.547.547 0 00-.776-.01.546.546 0 00.008.778l.768.768a.548.548 0 00.778.008m7.334 7.335a.545.545 0 00-.01-.775l-.768-.769a.547.547 0 00-.776-.01.546.546 0 00.008.779l.769.768a.548.548 0 00.777.007m-4.389-7.45c-1.655 0-2.998 1.327-2.998 2.965 0 1.635 1.343 2.965 2.998 2.965 1.657 0 3-1.33 3-2.965 0-1.638-1.343-2.965-3-2.965m.003-1.064c.3 0 .541-.243.541-.555V1.446A.547.547 0 0011.54.89a.546.546 0 00-.545.556v1.086c0 .309.242.555.545.555\"/></g>",
    "attrs": {
      "viewBox": "0 0 18 31",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});