define("ember-svg-jar/inlined/icn-edit-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g transform=\"translate(.1 .5)\" fill=\"none\" fill-rule=\"evenodd\"><rect stroke=\"#BBB8B3\" fill=\"#FFF\" x=\".5\" y=\".5\" width=\"19\" height=\"19\" rx=\"4\"/><path d=\"M15.334 6.186l-1.518-1.52a1.15 1.15 0 00-1.626 0l-6.61 6.616a1.23 1.23 0 00-.291.49l-.936 3.208c-.061.184 0 .384.138.537a.533.533 0 00.383.154c.046 0 .107 0 .153-.016l3.206-.936a1.23 1.23 0 00.49-.292l6.61-6.615a1.151 1.151 0 00.001-1.626zm-9.187 6.477l1.197 1.197-1.687.491.49-1.688zm8.42-5.602l-1.043 1.044-1.626-1.628 1.043-1.044c.016-.015.046-.015.061-.015a.12.12 0 01.062.015l1.518 1.52c.015.015.015.046.015.06 0 .017-.015.032-.03.048z\" fill=\"#BBB8B3\"/></g>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});