define("ember-svg-jar/inlined/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#BBB8B3\"><path d=\"M24.235 18.791a11.738 11.738 0 00-7.363-6.641 6.821 6.821 0 002.541-5.315A6.843 6.843 0 0012.58 0a6.843 6.843 0 00-6.835 6.835c0 2.145.99 4.06 2.54 5.315A11.71 11.71 0 000 23.345l.001 1.839a3.101 3.101 0 003.096 3.096h15.35a5.717 5.717 0 004.09 1.72 5.739 5.739 0 005.734-5.734c.003-2.571-1.7-4.75-4.035-5.474zM7.111 6.835a5.474 5.474 0 015.467-5.468 5.474 5.474 0 015.468 5.468 5.474 5.474 0 01-5.468 5.467 5.476 5.476 0 01-5.467-5.467zM3.1 26.913a1.73 1.73 0 01-1.73-1.73v-1.839c0-5.012 3.685-9.362 8.601-10.194a6.819 6.819 0 005.222 0 10.347 10.347 0 017.413 5.385h-.063a5.739 5.739 0 00-5.734 5.734c0 .954.236 1.855.649 2.647H3.1v-.003zm19.44 1.72a4.372 4.372 0 01-4.367-4.368A4.372 4.372 0 0122.54 19.9a4.372 4.372 0 014.366 4.366 4.372 4.372 0 01-4.366 4.367z\"/><path d=\"M23.224 23.584v-2.302a.683.683 0 00-1.364 0v2.302h-2.3a.683.683 0 000 1.364h2.301v2.301a.683.683 0 001.364 0v-2.301h2.302a.683.683 0 000-1.364h-2.302z\"/></g>",
    "attrs": {
      "width": "29",
      "height": "30",
      "viewBox": "0 0 29 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});