define("ember-svg-jar/inlined/icn-schedule-print-irrigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><path id=\"a\" d=\"M8.499 22A8.475 8.475 0 010 13.517c0-1.582.431-3.066 1.2-4.313.145-.289.336-.528.53-.768l.048-.049c.096-.144.192-.24.29-.335L8.498 0l6.483 8.003c.048.05.095.145.144.191l.145.191C16.327 9.823 17 11.596 17 13.513 16.998 18.166 13.205 22 8.5 22h-.001z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path stroke=\"#676767\" d=\"M8.436 21.5h.063c4.414 0 8-3.594 8.001-7.987 0-1.726-.584-3.405-1.628-4.826l-.092-.132a.902.902 0 01-.142-.178l-.001-.002a.475.475 0 00-.044-.057L8.502.798 2.417 8.41a1.43 1.43 0 00-.222.254l-.064.077-.012.009c-.26.322-.371.478-.493.716A7.698 7.698 0 00.5 13.517c0 4.4 3.535 7.95 7.936 7.983z\"/><g stroke=\"#676767\" stroke-linecap=\"round\" stroke-linejoin=\"round\" mask=\"url(#b)\"><path d=\"M8.358 10.403v4.248m1.562 1.876l-1.562-1.561\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "17",
      "height": "22",
      "viewBox": "0 0 17 22"
    }
  };
});