define("ember-svg-jar/inlined/icn-notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.884 16.81l-1.445-2.244V8.503c0-3.369-2.403-6.154-5.528-6.613V.945C8.91.423 8.503 0 8 0c-.503 0-.91.423-.91.945v.945c-3.127.46-5.53 3.244-5.53 6.613v6.063L.116 16.81a.993.993 0 00.005.942.88.88 0 00.77.469H15.11a.88.88 0 00.769-.47.996.996 0 00.005-.941zM8 22c1.143 0 2.128-1.115 2.55-2.7h-5.1C5.872 20.886 6.857 22 8 22z\" fill=\"#bbb8b3\"/>",
    "attrs": {
      "viewBox": "0 0 16 22",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});