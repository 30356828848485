define("ember-svg-jar/inlined/icn-clouds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#1196D9\" fill-rule=\"evenodd\" d=\"M16.17 3.533C15.593 1.503 13.695 0 11.437 0 9.458 0 7.76 1.132 6.981 2.77c-.1 0-.2-.019-.3-.019-1.619 0-2.937 1.249-3.017 2.81-.1 0-.2-.02-.3-.02C1.766 5.54.447 6.809.447 8.39c0 1.56 1.298 2.849 2.917 2.849h12.107c2.198 0 3.976-1.737 3.976-3.883 0-1.93-1.419-3.512-3.276-3.825z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 12"
    }
  };
});