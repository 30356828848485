define("layout-bin-packer/entry", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Entry {
    constructor(height, width, x, y) {
      this.height = height;
      this.width = width;
      this.position = {
        x: x,
        y: y
      };
    }
  }
  _exports.default = Entry;
});