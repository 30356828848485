define("ember-svg-jar/inlined/icn-new-tab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"#65615A\"><path d=\"M2.062 9.882H7.21c1.03 0 1.86-.829 1.86-1.859V4.877a.423.423 0 00-.43-.43.422.422 0 00-.429.43v3.146a.994.994 0 01-1 1.001h-5.15c-.557 0-1-.443-1-1v-5.15c0-.557.443-1 1-1h3.147c.243 0 .43-.186.43-.43a.422.422 0 00-.43-.429H2.062c-1.03 0-1.86.83-1.86 1.86v5.148c0 1.03.83 1.86 1.86 1.86z\"/><path d=\"M9.985 3.175V.558c0-.029 0-.057-.015-.086 0-.014 0-.029-.014-.029 0-.014-.014-.028-.014-.043 0-.014-.014-.028-.029-.042 0-.015-.014-.03-.014-.03a.453.453 0 00-.114-.113L9.755.2C9.728.157 9.7.143 9.671.143L9.628.129c-.028 0-.057-.015-.086-.015H6.924a.422.422 0 00-.429.43c0 .243.186.429.43.429h1.587L4.578 4.92a.413.413 0 000 .6.413.413 0 00.6 0l3.95-3.933v1.588c0 .243.186.429.429.429.229 0 .414-.2.429-.429z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10"
    }
  };
});