define("ember-svg-jar/inlined/icn-sensor-hail-fall", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15 2c-1.31 0-2.63.52-3.64 1.56-1.11 1.16-1.6 2.72-1.48 4.23h-.24C7.63 7.79 6 9.48 6 11.57c0 2.1 1.63 3.79 3.64 3.79h10.07c2.37 0 4.29-2 4.29-4.45 0-2.34-1.73-4.25-3.93-4.44a5.366 5.366 0 00-1.44-2.9C17.63 2.52 16.32 2 15 2zM13.97 26.62v.007c0 .693.57 1.263 1.263 1.263h.007c.34 0 .66-.14.9-.37.24-.24.37-.56.37-.9v-.007c0-.693-.57-1.263-1.263-1.263h-.007c-.7 0-1.27.57-1.27 1.27zM15.65 20c.17 0 .33.07.45.19s.18.28.18.45v2.53c0 .17-.06.34-.18.46s-.28.19-.45.19c-.17 0-.33-.07-.45-.19a.646.646 0 01-.2-.45v-2.53c0-.17.07-.34.19-.46a.661.661 0 01.46-.19zM20 24.27v.007c0 .693.57 1.263 1.263 1.263h.007c.34 0 .66-.14.9-.37.24-.24.37-.56.37-.9a1.263 1.263 0 00-1.266-1.268A1.28 1.28 0 0020 24.27zm.65-7.27c.17 0 .33.07.45.19s.18.28.18.45v2.53c0 .17-.06.34-.18.46s-.28.19-.45.19c-.17 0-.33-.07-.45-.19a.646.646 0 01-.2-.45v-2.53c0-.17.07-.34.19-.46a.661.661 0 01.46-.19zM8 24.27v.007c0 .693.57 1.263 1.263 1.263h.007c.34 0 .66-.14.9-.37.24-.24.37-.56.37-.9a1.263 1.263 0 00-1.266-1.268A1.28 1.28 0 008 24.27zM9.65 17c.17 0 .33.07.45.19s.18.28.18.45v2.53c0 .17-.06.34-.18.46-.13.12-.29.19-.46.19a.66.66 0 01-.46-.19.627.627 0 01-.18-.45v-2.53c0-.17.07-.34.19-.46a.661.661 0 01.46-.19z\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});