define("ember-svg-jar/inlined/icn-cross-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.5 6.088L7.082 8.67c.211.215.501.337.802.337a1.13 1.13 0 001.124-1.124c0-.302-.121-.592-.337-.803L6.089 4.5 8.67 1.917A1.123 1.123 0 007.08.33L4.5 2.91 1.917.33a1.124 1.124 0 00-.805-.34A1.122 1.122 0 10.33 1.919l2.581 2.58L.33 7.083A1.125 1.125 0 001.918 8.67L4.5 6.088z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 9 9",
      "xmlns": "http://www.w3.org/2000/svg",
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "stroke-linejoin": "round",
      "stroke-miterlimit": "1.414"
    }
  };
});