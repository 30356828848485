define("ember-svg-jar/inlined/icn-schedule-print-irrigation-inferred", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"none\" stroke=\"#676767\" d=\"M8.436 21.5h.063c4.414 0 8-3.594 8.001-7.987 0-1.726-.584-3.405-1.628-4.826l-.092-.132a.902.902 0 01-.142-.178l-.001-.002a.475.475 0 00-.044-.057L8.502.798 2.417 8.41a1.43 1.43 0 00-.222.254l-.064.077-.012.009c-.26.322-.371.478-.493.716A7.698 7.698 0 00.5 13.517c0 4.4 3.535 7.95 7.936 7.983z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 22"
    }
  };
});