define("ember-collection/components/ember-collection/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <EmberNativeScrollable @content-size={{this._contentSize}} @scroll-left={{this._scrollLeft}} @scroll-top={{this._scrollTop}} @scrollChange={{this.scrollChange}} @clientSizeChange={{this.clientSizeChange}}>
    <div>
      {{~#each this._cells as |cell|~}}
        <div style={{{cell.style}}}>{{yield cell.item cell.index }}</div>
      {{~/each~}}
    </div>
  </EmberNativeScrollable>
  
  */
  {
    "id": "grcVxqOf",
    "block": "[[[8,[39,0],null,[[\"@content-size\",\"@scroll-left\",\"@scroll-top\",\"@scrollChange\",\"@clientSizeChange\"],[[30,0,[\"_contentSize\"]],[30,0,[\"_scrollLeft\"]],[30,0,[\"_scrollTop\"]],[30,0,[\"scrollChange\"]],[30,0,[\"clientSizeChange\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[12],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"_cells\"]]],null]],null],null,[[[10,0],[22,5,[30,1,[\"style\"]]],[12],[18,2,[[30,1,[\"item\"]],[30,1,[\"index\"]]]],[13]],[1]],null],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"cell\",\"&default\"],false,[\"ember-native-scrollable\",\"div\",\"each\",\"-track-array\",\"yield\"]]",
    "moduleName": "ember-collection/components/ember-collection/template.hbs",
    "isStrictMode": false
  });
});