define("ember-svg-jar/inlined/icn-schedule-double-fertigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><linearGradient id=\"a\" x1=\"50%\" x2=\"50%\" y1=\"100%\" y2=\"0%\"><stop offset=\"0%\" stop-color=\"#62B881\"/><stop offset=\"100%\" stop-color=\"#6ED191\"/></linearGradient><filter id=\"b\" width=\"141.7%\" height=\"132.6%\" x=\"-12.5%\" y=\"-9.8%\" filterUnits=\"objectBoundingBox\"><feOffset dx=\"3\" dy=\"3\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\" stdDeviation=\"2\"/><feColorMatrix in=\"shadowBlurOuter1\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0\"/></filter><path id=\"c\" d=\"M55.998 310C46.03 310 38 302.085 38 292.263c0-3.307.914-6.412 2.542-9.019.306-.603.711-1.104 1.12-1.605l.104-.102c.202-.301.405-.5.611-.7L55.998 264l13.728 16.735c.103.101.203.3.306.399l.306.4c2.236 3.005 3.662 6.713 3.662 10.72C73.996 301.983 65.964 310 55.998 310z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"url(#a)\" d=\"M61.998 310C52.03 310 44 302.085 44 292.263c0-3.307.914-6.412 2.542-9.019.306-.603.711-1.104 1.12-1.605l.104-.102c.202-.301.405-.5.611-.7L61.998 264l13.728 16.735c.103.101.203.3.306.399l.306.4c2.236 3.005 3.662 6.713 3.662 10.72C79.996 301.983 71.964 310 61.998 310z\" transform=\"translate(-33 -256)\"/><g transform=\"translate(-33 -256)\"><use fill=\"#000\" filter=\"url(#b)\" xlink:href=\"#c\"/><use fill=\"#6ED191\" xlink:href=\"#c\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "52",
      "height": "58",
      "viewBox": "0 0 52 58"
    }
  };
});