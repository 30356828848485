define("ember-svg-jar/inlined/icn-sensor-degree-days", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icnDegreeDays</title><path d=\"M14.511 6.696c0-2.317-1.778-4.189-4.009-4.189-2.23 0-4.009 1.872-4.009 4.189v10.057c-1.252 1.173-2.004 2.732-2.004 4.44 0 3.391 2.79 6.228 6.083 6.228 3.273 0 5.943-2.82 5.943-6.228 0-1.708-.751-3.267-2.004-4.44V6.696zm-2.005 10.539c0 .319.142.62.385.817 1.024.83 1.62 1.948 1.62 3.14 0 2.272-1.781 4.152-3.94 4.152-2.192 0-4.078-1.915-4.078-4.151 0-1.193.597-2.31 1.621-3.14.243-.197.384-.499.384-.818V6.695c0-1.177.888-2.112 2.004-2.112 1.117 0 2.004.935 2.004 2.113v10.539z\"/><path d=\"M11.57 18.404v-2.176c0-.492-.274-.996-1.036-.996-.761 0-1.036.41-1.036.996v2.176c-1.205.411-2.072 1.507-2.072 2.806 0 1.65 1.392 2.988 3.108 2.988 1.717 0 3.109-1.338 3.109-2.988 0-1.3-.868-2.395-2.072-2.806M17.899 4.647v.324h-.97c-.712 0-1.295.582-1.295 1.294v7.44c0 .712.583 1.295 1.294 1.295h7.118c.711 0 1.294-.583 1.294-1.294V6.265c0-.712-.583-1.294-1.294-1.294h-.97v-.324a.647.647 0 10-1.295 0v.324h-2.588v-.324a.647.647 0 00-1.294 0zm-.97 9.059v-4.53h7.117v4.53h-7.118zm4.852-7.441v.323a.647.647 0 101.294 0v-.323h.97v1.617h-7.117V6.265h.97v.323a.647.647 0 101.295 0v-.323h2.588z\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});