define("ember-svg-jar/inlined/icn-edit-poly-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#F4F4F3\" d=\"M-307-34h512v93h-512z\"/><g transform=\"translate(3.036 2.5)\"><path stroke=\"#65615A\" stroke-width=\"2\" d=\"M3 3h18v18H3z\"/><circle cx=\"3.5\" cy=\"3.5\" r=\"3.5\" fill=\"#65615A\"/><circle cx=\"3.5\" cy=\"20\" r=\"3.5\" fill=\"#65615A\"/><circle cx=\"20.5\" cy=\"20\" r=\"3.5\" fill=\"#65615A\"/><circle cx=\"19.5\" cy=\"3.5\" r=\"3.5\" fill=\"#65615A\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "29",
      "viewBox": "0 0 30 29"
    }
  };
});