define("ember-svg-jar/inlined/icn-tension-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FAD344\" d=\"M18 4.791v.599c0 3.69-2.951 6.691-6.578 6.691h-1.53V24.5H9.5C9.357 13.5 9.19 8 9 8 5.373 8 1 4.79 1 1.1V.5h3.172c3.145 0 5.704 2.594 5.72 5.789v1.384c.995-1.723 2.834-2.881 4.936-2.881H18zM6.899 22.775a.362.362 0 00-.002-.504l-1.615-1.645c-.376-.382-.942.19-.565.572l.705.713c.117.119.077.215-.087.215H3.4c-.22 0-.4.181-.4.405 0 .223.18.404.4.404h1.935c.165 0 .204.096.087.216l-.705.712c-.377.382.189.954.565.572l1.617-1.66zm5.202-.489a.362.362 0 00.002.504l1.615 1.646c.376.381.942-.191.565-.573l-.705-.713c-.117-.119-.077-.215.087-.215H15.6c.22 0 .4-.18.4-.404a.402.402 0 00-.4-.405h-1.935c-.165 0-.204-.096-.087-.215l.705-.713c.377-.382-.189-.954-.565-.572l-1.617 1.66z\"/><path stroke=\"#FAD344\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\" d=\"M1.144 19.079c6.729-2.853 10.328-2.753 17.317.25M9.6 7.894V25\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 26"
    }
  };
});