define("ember-svg-jar/inlined/icn-sensor-air-temperature", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.23 19.24v-2.22c0-.5-.28-1.02-1.06-1.02s-1.06.42-1.06 1.02v2.22c-1.22.42-2.11 1.54-2.11 2.87 0 1.69 1.42 3.05 3.18 3.05s3.18-1.37 3.18-3.05c-.01-1.33-.9-2.45-2.13-2.87z\"/><path d=\"M18.69 10.58c1.04 0 2.02-.4 2.75-1.13.5-.49 1.16-.76 1.86-.76s1.36.27 1.86.76c.25.24.65.24.9 0 .12-.12.19-.28.19-.45s-.07-.33-.19-.45a3.905 3.905 0 00-2.76-1.13c-1.04 0-2.02.4-2.75 1.13-.5.49-1.16.76-1.86.76-.52 0-1.03-.15-1.45-.43V7.63c.46.18.95.28 1.45.28 1.04 0 2.02-.4 2.75-1.13.5-.49 1.16-.76 1.86-.76s1.36.27 1.86.76c.25.24.65.24.9 0 .12-.12.19-.28.19-.45 0-.17-.07-.33-.19-.45a3.905 3.905 0 00-2.76-1.13c-1.04 0-2.02.4-2.75 1.13-.5.49-1.16.76-1.86.76-.61 0-1.18-.21-1.64-.59C16.55 4.29 15.01 3 13.14 3c-2.28 0-4.1 1.91-4.1 4.28v10.27C7.77 18.75 7 20.34 7 22.09c0 3.46 2.85 6.36 6.21 6.36 3.34 0 6.07-2.88 6.07-6.36 0-1.74-.77-3.34-2.05-4.54v-4.58c.46.18.95.28 1.45.28 1.04 0 2.02-.4 2.75-1.13.5-.49 1.16-.76 1.86-.76s1.36.27 1.86.76c.25.24.65.24.9 0 .12-.12.19-.28.19-.45 0-.17-.07-.33-.19-.45a3.905 3.905 0 00-2.76-1.13c-1.04 0-2.02.4-2.75 1.13-.5.49-1.16.76-1.86.76-.52 0-1.03-.15-1.45-.43V10.3c.46.18.96.28 1.46.28zm-1.45 11.51c0 2.32-1.82 4.24-4.02 4.24-2.24 0-4.17-1.96-4.17-4.24 0-1.22.61-2.36 1.66-3.21.25-.2.39-.51.39-.84V7.28c0-1.2.91-2.16 2.05-2.16s2.05.96 2.05 2.16v10.77c0 .33.14.63.39.84 1.04.84 1.65 1.98 1.65 3.2z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 31 31"
    }
  };
});