define("ember-svg-jar/inlined/icn-position", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M15.224 21.186L9.92 14.828a1.023 1.023 0 00-.6-.36l-8.104-1.699c-.89-.199-1.13-1.399-.352-1.893L16.536.938a1.29 1.29 0 011.313-.027c.388.234.648.679.592 1.172l-1.417 18.503c-.036.94-1.208 1.291-1.8.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 22"
    }
  };
});