define("ember-svg-jar/inlined/icn-live-system-health-battery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path class=\"st0\" d=\"M17.37 5.34V3.06h-4.98v2.27H8.5v21.11h12.75V5.34z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 30 30"
    }
  };
});