define("ember-svg-jar/inlined/icn-schedule-mixed-irrigation-first", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter id=\"a\" x=\"-12.2%\" y=\"-9.5%\" width=\"142.2%\" height=\"132.8%\"><feOffset dx=\"4\" dy=\"4\" in=\"SourceAlpha\"/><feGaussianBlur stdDeviation=\"2.5\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0\"/></filter><path id=\"b\" d=\"M360.497 317C348.04 317 338 307.02 338 294.636c0-4.17 1.142-8.084 3.178-11.372.382-.76.888-1.39 1.4-2.023l.13-.13c.253-.38.506-.63.765-.883L360.498 259l17.16 21.1c.13.128.253.38.382.503l.382.504c2.795 3.8 4.578 8.465 4.578 13.52C382.995 306.89 372.956 317 360.498 317z\"/></defs><g fill=\"none\"><path d=\"M36.497 68C24.04 68 14 58.02 14 45.636c0-4.17 1.142-8.084 3.178-11.372.382-.76.888-1.39 1.4-2.023l.13-.13c.253-.38.506-.63.765-.883L36.498 10l17.16 21.1c.13.128.253.38.382.503l.382.504c2.795 3.8 4.578 8.465 4.578 13.52C58.995 57.89 48.956 68 36.498 68h-.001z\" fill=\"#7CD59C\"/><g transform=\"translate(-331 -249)\"><use filter=\"url(#a)\" xlink:href=\"#b\" fill=\"#000\"/><use xlink:href=\"#b\" fill=\"#19ACE4\"/></g><path d=\"M29.273 33.455V48.28m8.052 7.98l-8.052-7.667\" stroke=\"#FFF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"5\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 65 76"
    }
  };
});